import {Component, OnInit} from '@angular/core';
import {AuthService} from '../service/auth/auth.service';
import {User} from '../class/user';
import {UserService} from '../service/user/user.service';
import {Organization} from '../class/organization';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  currentUser: User;
  currentOrganization: Organization;

  constructor(private authService: AuthService,
              private userService: UserService,
              private activatedRoute: ActivatedRoute) {

  }

  logout() {
    this.authService.logout();
    this.authService.redirectToLogin();
  }

  ngOnInit(): void {
    this.currentUser = this.authService.getCurrentUser();
    this.userService.getOrganization().subscribe(resp => {
      this.currentOrganization = new Organization(resp);
    });
  }

  isListPageActive() {
    return (this.activatedRoute.firstChild && this.activatedRoute.firstChild.snapshot.url.toString() === 'list');
  }
}
