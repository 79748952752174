import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../service/auth/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../service/user/user.service';
import {ToastService} from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-confirm-email-change',
  templateUrl: './confirm-email-change.component.html',
  styleUrls: ['./confirm-email-change.component.scss']
})
export class ConfirmEmailChangeComponent implements OnInit {

  hash: string;
  currentEmail: string;
  newEmail: string;

  loadingConfirm = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private toastService: ToastService
  ) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.hash = params.hash;
      this.currentEmail = params.currentEmail;
      this.newEmail = params.newEmail;
    });
  }

  confirm(): void {
    if (this.loadingConfirm) {
      return;
    }

    this.loadingConfirm = true;

    this.userService.confirmEmailChange(this.hash).subscribe(
      () => {
        this.loadingConfirm = false;

        this.toastService.success('E-maili vaheteamine õnnestus.');

        this.authService.logout();
        this.authService.redirectToLogin();
      },
      (err) => {
        let errorMessage = 'Viga';

        if (err.status === 404) {
          errorMessage = 'Link on kehtetu';
        } else if (err.status === 400) {
          errorMessage = 'Link on aegunud';
        }

        this.toastService.error(errorMessage);

        this.loadingConfirm = false;
      }
    );
  }
}
