import {Image} from './image';
import {DomainValue} from './domain-value';
import {isString} from 'util';

export class AdvertLite {
  addressLite: string;
  booked: boolean;
  closedNetArea: number;
  floor: number;
  floors: number;
  id: number;
  includesVat: boolean;
  intendedUsages: string;
  objectType: string;
  price: number;
  pricePerM2: number;
  propertyArea: number;
  rooms: number;
  avatar: Image;
  bookedUntil: Date;
  status: string; // @todo: lisada enum klass
  type: string; // enum
  vat: string; // enum
  dateCreated: Date;
  isMock = false;

  constructor(json?: any) {
    if (!json) {
      return;
    }

    this.id = json.id;
    this.addressLite = json.address;
    this.booked = json.booked;
    this.closedNetArea = json.closedNetArea;
    this.floor = json.floor;
    this.floors = json.floors;
    this.includesVat = json.includesVat;
    this.intendedUsages = json.intendedUsages;
    this.objectType = json.objectType;
    this.price = json.price;
    this.propertyArea = json.propertyArea;
    this.pricePerM2 = json.pricePerM2;
    this.rooms = json.rooms;
    this.avatar = new Image(json.avatar);
    this.bookedUntil = new Date(json.bookedUntil);
    this.dateCreated = new Date(json.dateCreated);
    this.status = json.status;
    this.type = json.type;
    this.vat = json.vat;

  }

  getFloorsString() {
    const res = [];

    res.push(this.floor ? this.floor : '-');
    res.push(this.floors ? this.floors : '-');

    return res.join('/');
  }

  getAreaToDisplay() {
    if (this.objectType === DomainValue.objectTypeHouse ||
      this.objectType === DomainValue.objectTypeApartment ||
      this.objectType === DomainValue.objectTypeHouseShare) {
      return this.closedNetArea;
    } else {
      return this.propertyArea;
    }
  }

  getCityAndCountyString() {
    const addressSplit = this.addressLite.split(', ');
    return addressSplit.slice(addressSplit.length - 2).join(', ');
  }

  getAsArray(fieldName) {
    if (!isString(this[fieldName])) {
      return [];
    }
    return this[fieldName].split(',');
  }

  isMockAdvert() {
    return this.id === 0;
  }

  getFormattedPrice() {
    return (this.price === null ? null : this.price.toLocaleString());
  }
}
