import {AdvertLite} from './advert-lite';
import {Image} from './image';
import {Organization} from './organization';
import {User} from './user';

export class UserProfile {
  id: number;
  adverts: AdvertLite[] = [];
  description: any;
  // id: number;
  image: Image = null;
  languages: any[] = [];
  organization: Organization;
  user: User;
  regions: any[] = [];

  constructor(json?: any) {
    if (json) {
      if (json.adverts) {
        for (let i = 0; i < json.adverts.length; i++) {
          this.adverts.push(new AdvertLite(json.adverts[i]));
        }
      }
      this.id = json.id;
      this.regions = json.regions;
      this.description = json.description;
      if (json.image) {
        this.image = new Image(json.image);
      }

      this.languages = json.languages;
      this.organization = new Organization(json.organization);
      if (json.user) {
        this.user = new User(json.user);
      }
    } else {
      this.description = {
        et: '',
        en: ''
      };
    }
  }

  getPostJson() {
    const data = {
      // adverts: this.getAdvertsValue(),
      languages: this.languages,
      description: this.description,
      image: this.image ? this.image.id : null,
      organization: this.organization ? this.organization.id : null,
      regions: this.regions,
      user: this.user.id,
      // videos: this.getVideosValue()
    };

    return JSON.stringify(data);
  }

}
