import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../service/auth/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder} from '@angular/forms';
import {ToastService} from 'ng-uikit-pro-standard';
import {UserService} from '../../service/user/user.service';
import {Error} from '../../class/error';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  saveInProgress = false;
  password = '';
  passwordRepeat = '';
  hash = '';

  constructor(
    private authService: AuthService,
    private router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private toastService: ToastService
  ) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.hash = params.hash;
    });
  }

  resetPassword() {
    if (this.saveInProgress) {
      return;
    }
    this.saveInProgress = true;

    if (!this.password || !this.passwordRepeat || this.password !== this.passwordRepeat) {
      this.toastService.error('Palun sisesta klappivad paroolid');
      this.saveInProgress = false;
      return;
    }

    this.userService.resetPassword(this.password, this.hash).subscribe(response => {
      this.toastService.success('Parool muudetud!');
      this.authService.redirectToLogin();

    }, errorResponse => {
      let errorMessage = 'Viga';
      if (errorResponse.status === 404) {
        errorMessage = 'Link on kehtetu';
      } else if (errorResponse.status === 400) {
        if (errorResponse.error.code === Error.errorValidation) {
          errorMessage = 'Parool ei vasta nõuetele';
        } else {
          errorMessage = 'Link on aegunud';
        }
      }

      this.toastService.error(errorMessage);
      this.saveInProgress = false;
    });
  }
}

