import {Injectable} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AdvertLite} from '../../class/advert-lite';
import {map} from 'rxjs/operators';
import {DataService} from '../data/data.service';
import {Advert} from '../../class/advert';
import {Router} from '@angular/router';
import {DomainValueService} from '../domain-value/domain-value.service';
import {ImageService} from '../image/image.service';
import {Transaction} from '../../class/transaction';

@Injectable({
  providedIn: 'root'
})
export class AdvertService {

  constructor(private dataService: DataService,
              private domainValueService: DomainValueService,
              private imageService: ImageService,
              private router: Router) {
  }

  public static advertsUrl = 'adverts';
  public static NEW_ADVERT_URL = 'adverts/ultralight';
  public static UPDATE_ADVERT_URL = 'adverts';
  public static PATCH_ADVERT_URL = 'adverts/{id}';
  public static TOGGLE_FIELD_VISIBILITY_URL = 'adverts/{id}/fields';
  public static ARCHIVE_ADVERTISEMENT_URL = 'adverts/{id}/archive';
  public static GET_SIMILAR_TRANSACTIONS = 'adverts/{id}/similar-adverts';

  public static ehrUrl = 'https://www.ehr.ee/app/esileht?0-1.IFormSubmitListener-columns-searchPanel-searchFormPanel' +
    '-searchForm&searchFieldWithButton%3AsearchParameter=';
  public static knrUrl = 'https://kinnistusraamat.rik.ee/detailparing/pank.aspx';

  static getCompanies() {
    return [['kv', 'www.kv.ee'], ['city24', 'www.city24.ee'],
      ['kinnisvara24', 'kinnisvara24.ee'], ['soov', 'www.soov.ee']];
  }

  static getCompaniesAsSelectElement() {
    const result = [];
    AdvertService.getCompanies().forEach((element, index) => {
      result.push({
        value: element[0],
        label: element[1]
      });
    });
    return result;
  }

  public getAdverts(params?: HttpParams): Observable<AdvertLite[]> {
    return this.dataService.getList(AdvertService.advertsUrl, params).pipe(
      map((response: any) => {
        if (response) {
          const res: AdvertLite[] = [];
          for (let i = 0; i < response['content'].length; i++) {
            res.push(new AdvertLite(response['content'][i]));
          }
          return res;
        }
      })
    );
  }

  public getAdvert(id: number, params?: HttpParams): Observable<Advert> {
    if (!params) {
      params = new HttpParams();
    }
    params = params.append('restrict', 'false');

    return this.dataService.getOne(AdvertService.advertsUrl + '/' + id, params).pipe(
      map(response => {
        return new Advert(response);
      })
    );
  }

  public createNewAdvert(newAdvertData: Object) {
    return this.dataService.post(AdvertService.NEW_ADVERT_URL, newAdvertData);
  }

  public updateAdvert(advert: Advert, advertData) {
    return this.dataService.put(AdvertService.UPDATE_ADVERT_URL + '/' + advert.id, advertData);
  }

  public changeFieldVisibility(advert: Advert, fieldName: string, isVisible: boolean) {
    const url = AdvertService.TOGGLE_FIELD_VISIBILITY_URL.replace('{id}', advert.id.toString());
    return this.dataService.put(url, {
      'name': fieldName,
      'visible': isVisible
    });
  }

  public archiveAdvert(advert: Advert | AdvertLite, data) {
    const url = AdvertService.ARCHIVE_ADVERTISEMENT_URL.replace('{id}', advert.id.toString());
    return this.dataService.patch(url, data);
  }

  public patchAdvert(advert: Advert | AdvertLite, data) {
    const url = AdvertService.PATCH_ADVERT_URL.replace('{id}', advert.id.toString());
    return this.dataService.patch(url, data);
  }

  copyAdvert(oldAdvert: Advert): Promise<number> {
    const that = this;
    const newAdLiteData = {
      'address': {
        'country': 'est',
        'externalId': oldAdvert.address.externalId,
        'sublocality': oldAdvert.address.subLocality
      },
      'cadasterNumber': oldAdvert.cadasterNumber,
      'registerNumber': oldAdvert.registerNumber,
      'type': oldAdvert.objectType
    };

    return new Promise<number>(function (resolve, reject) {
      that.createNewAdvert(newAdLiteData).subscribe(newAdvertData => {
          const newAdvert = new Advert(newAdvertData);
          const updatedAdData = that.createUpdateAdvertDataObject(oldAdvert);
          that.updateAdvert(newAdvert, updatedAdData).subscribe(adResponse => {

            // copy PATCH-only values
            const patchData = {price: oldAdvert.price, cadasterImageAsLast: oldAdvert.cadasterImageAsLast};
            that.patchAdvert(newAdvert, patchData).subscribe(patchedAdResponse => {

              // copy images here and then call the resolve&route
              oldAdvert.images.forEach((image, index) => {
                that.imageService.addImageToAdvert(newAdvert.id, {
                  'image': image.imageId,
                  'sortOrder': image.sortOrder
                }).subscribe(_ => {
                  if (index === oldAdvert.images.length - 1) {
                    resolve(1);
                    that.router.navigate(['/advert/edit/', newAdvert.id]);
                  }
                });
              });
            }, error => {
              reject(1);
            });
          }, error => {
            reject(1);
          });
        },
        error => {
          reject(1);
        });
    });
  }


  public getKnrParams(advert: Advert) {
    let queryString = '?';
    const params: any[] = [];

    const cadasterNumber = advert.cadasterNumber ? advert.cadasterNumber : null;

    if (cadasterNumber) {
      return queryString += 'kt=' + cadasterNumber;
    } else {
      return queryString += 'Aa=' + this.getEncodedAddress(advert, false);
    }
  }

  public getEncodedAddress(advert: Advert, withoutRoom: boolean = true) {
    // adds address to query
    let queryParam = advert.address.address;

    // removes room from query (if needed)
    if (queryParam && withoutRoom && advert.address.room) {
      queryParam = queryParam.replace('-' + advert.address.room, '');
    }

    return encodeURIComponent(queryParam);
  }

  getKnrUrl(advert: Advert): string {
    return AdvertService.knrUrl + this.getKnrParams(advert);
  }

  getEhrUrl(advert: Advert) {
    return AdvertService.ehrUrl + this.getEncodedAddress(advert);
  }

  getKnrUrlFromCadasterNumber(cadasterNumber: String) {
    return AdvertService.knrUrl + '?kt=' + cadasterNumber;
  }

  populateAddressFromInAds(ad: Advert, InAdsResponse) {
    ad.address.street = InAdsResponse['liikluspind'];
    ad.address.subLocality = '';
    ad.address.house = InAdsResponse['aadress_nr'];
    ad.address.locality = InAdsResponse['asustusyksus'];
    ad.address.room = (InAdsResponse['kort_nr'] ? InAdsResponse['kort_nr'] : '');
    ad.address.city = InAdsResponse['omavalitsus'];
    ad.address.county = InAdsResponse['maakond'];
    ad.address.externalId = InAdsResponse['ads_oid'];

  }

  createUpdateAdvertDataObject(advert: Advert) {
    return {
      'booked': advert.booked,
      'closedNetArea': advert.closedNetArea,
      'floor': advert.floor,
      'floors': advert.floors,
      'intendedUsages': advert.intendedUsages,
      'objectType': advert.objectType,
      'propertyArea': advert.propertyArea,
      'rooms': advert.rooms,
      'bookedUntil': advert.bookedUntil,
      'type': advert.type,
      'showApartmentNumber': advert.showApartmentNumber,
      'showStreetNumber': advert.showStreetNumber,
      'additionalInfo': advert.additionalInfo.join(','),
      'address': {
        'externalId': advert.address.externalId,
        'sublocality': advert.address.subLocality,
        'country': advert.address.country,
      },
      'ownership': advert.ownership,
      'cadasterNumber': advert.cadasterNumber,
      'buildYear': advert.buildYear,
      'supportingStructure': advert.supportingStructure,
      'sanitaryFurnitureState': advert.sanitaryFurnitureState,
      'heatingSystem': advert.heatingSystem,
      'coldWater': advert.coldWater,
      'ventilation': advert.ventilation,
      'security': advert.security,
      'stove': advert.stove,
      'parking': advert.parking,
      'registerNumber': '',
      'energyLabel': advert.energyLabel,
      'roof': advert.roof,
      'sanitary': advert.sanitary,
      'sewerage': advert.sewerage,
      'gasSupply': advert.gasSupply,
      'communications': advert.communications,
      'alarm': advert.alarm,
      'salesSentence': advert.salesSentence,
      'description': advert.description,
      'bedroomCount': advert.bedroomCount,
      'cadasterImageAsLast': advert.cadasterImageAsLast
    };
  }

  getObjectTypeGroupForAdvert(advert: AdvertLite) {
    let result = '';
    this.domainValueService.getObjectTypeGroups().forEach(groupString => {
      if (result) {
        return;
      }

      const domainValuesInGroup = this.domainValueService.getDomainValuesByGroup(groupString);
      const filteredGroup = domainValuesInGroup.filter(domainValueInGroup => {
        return domainValueInGroup.code === advert.objectType;
      });

      if (filteredGroup.length > 0) {
        result = groupString;
        return;
      }
    });

    return (result ? result : null);
  }

  public getSimilarTransactions(advert: Advert, params?: HttpParams): Observable<Transaction[]> {
    const url = AdvertService.GET_SIMILAR_TRANSACTIONS.replace('{id}', advert.id.toString());
    return this.dataService.getList(url, params).pipe(
      map((response: any) => {
        if (response) {
          const res: Transaction[] = [];
          for (let i = 0; i < response.length; i++) {
            res.push(new Transaction(response[i]));
          }
          return res;
        }
      })
    );
  }
}
