export class User {

  id: number;
  firstName = '';
  lastName = '';
  fullName = '';
  email = '';
  phoneNumber = '';
  profession = '';
  certificateNumber = '';
  publicEmail = '';
  hasPassword = false;
  isFacebookConnected = false;
  isGoogleConnected = false;
  status = '';

  constructor(json?: any) {
    if (json) {
      this.id = json.id;
      this.firstName = json.firstName;
      this.email = json.email;
      this.fullName = json.fullName;
      this.lastName = json.lastName;
      this.hasPassword = json.hasPassword;
      this.phoneNumber = json.phoneNumber;
      this.profession = json.profession;
      this.publicEmail = json.publicEmail;
      this.certificateNumber = json.certificateNumber;
      this.isGoogleConnected = json.isGoogleConnected;
      this.isFacebookConnected = json.isFacebookConnected;
      this.status = json.status;
    }
  }

  isNotActivated() {
    return this.status === 'INVITED';
  }
}
