import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthService} from '../service/auth/auth.service';

export class AddHeaderInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const xAuthToken = (localStorage.getItem(AuthService.X_AUTH_TOKEN) ? localStorage.getItem(AuthService.X_AUTH_TOKEN) : '');

    // Clone the request to add the new header
    const httpHeaders = new HttpHeaders({
      'X-Auth-Token': xAuthToken,
      'Content-Type': 'application/json'
    });
    req = req.clone({headers: httpHeaders, withCredentials: true});

    // Pass the cloned request instead of the original request to the next handle
    return next.handle(req);
  }
}
