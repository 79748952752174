import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UserService} from '../../service/user/user.service';
import {FormValidators} from '../../validator/form-validators';
import {InputValidators} from '../../validator/input-validators';
import {ModalDirective, ToastService} from 'ng-uikit-pro-standard';
import {AuthService} from '../../service/auth/auth.service';
import {User} from '../../class/user';
import {FileUploader} from 'ng2-file-upload';
import {Config} from '../../config/config';
import {UserProfile} from '../../class/user-profile';
import {County} from '../../class/county';
import {Image} from '../../class/image';
import {GooglePlaceDirective} from 'ngx-google-places-autocomplete';
import {Options as GPOptions} from 'ngx-google-places-autocomplete/objects/options/options';
import {Address} from 'ngx-google-places-autocomplete/objects/address';
import {AuthService as SocialLoginAuthService, FacebookLoginProvider, GoogleLoginProvider} from 'angularx-social-login';
import {ActivatedRoute} from '@angular/router';

declare var google: any;

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit, AfterViewInit {

  public static UPLOAD_IMAGE_URL = 'images';

  // regions related:
  @ViewChild('placesRef') placesRef: GooglePlaceDirective;
  @ViewChild('ngxGooglePlacesInput') ngxGooglePlacesInput: ElementRef;
  @Input() gpOptions: GPOptions = {
    bounds: null,
    types: [],
    componentRestrictions: {country: 'EE'}
  };
  @Input() inputClass = 'form-control m-input';
  @Input() inputPlaceholder = 'Aadressi valimine';

  currentUser: User;
  userProfile: UserProfile;
  @ViewChild('editPassword') editPasswordModal: ModalDirective;
  @ViewChild('editEmail') editEmailModal: ModalDirective;
  regionSelectOptions: Array<any>;
  languageSelectOptions = [
    {value: 'et', label: 'eesti'},
    {value: 'en', label: 'inglise'},
    {value: 'ru', label: 'vene'}
  ];
  changePasswordForm: FormGroup;
  passwordFormSubmitted = false;
  oldPasswordWrong = false;

  changeEmailForm: FormGroup;
  changeEmailFormSubmitted = false;
  submitProfileInProgress = false;
  submitEditPasswordInProgress = false;
  submitEditEmalInProgress = false;
  emailFormPasswordWrong = false;

  userProfileForm: FormGroup;
  userProfileFormSubmitted = false;

  imageLoading = false;

  public uploader: FileUploader;

  constructor(private formBuilder: FormBuilder,
              private userService: UserService,
              private config: Config,
              private toastService: ToastService,
              private socialLoginAuthService: SocialLoginAuthService,
              private route: ActivatedRoute,
              protected authService: AuthService) {
  }

  get passwordForm() {
    return this.changePasswordForm.controls;
  }

  get getPasswordForm() {
    return this.changePasswordForm.controls;
  }

  get getEmailForm() {
    return this.changeEmailForm.controls;
  }

  get getUserProfileForm() {
    return this.userProfileForm.controls;
  }

  ngOnInit() {
    this.initImageUpload();
    this.currentUser = this.authService.getCurrentUser();
    this.initPasswordForm();
    this.initEmailForm();
    this.initUserProfileForm();
    this.getUserProfileAndPopulate();
    this.regionSelectOptions = County.makeSelectData();
  }

  ngAfterViewInit() {
    if (this.route.snapshot.queryParamMap.get('redirect')) {
      if (this.route.snapshot.queryParamMap.get('redirect') === 'pw-modal') {
        this.editPasswordModal.show();
      }
    }
  }

  getUserProfileAndPopulate() {
    this.userService.getUserProfile(this.authService.getCurrentUser().id).subscribe(userProfileData => {

      this.userProfile = new UserProfile(userProfileData);
      this.populateUserProfileForm();

    }, _ => {
      const data = new UserProfile();
      data.user = this.currentUser;
      data.languages = ['et'];

      this.userService.createProfile(data.getPostJson()).subscribe(userProfileData => {
        this.userProfile = new UserProfile(userProfileData);
        this.populateUserProfileForm();
      });
    });
  }

  populateUserProfileForm() {
    this.getUserProfileForm.userProfileLanguages.setValue(this.userProfile.languages);
    this.getUserProfileForm.userProfileDescription.setValue(this.userProfile.description.et);
  }

  initImageUpload() {
    this.uploader = new FileUploader({
      url: this.config.getApiUrl(UserProfileComponent.UPLOAD_IMAGE_URL),
      headers: [{name: AuthService.X_AUTH_TOKEN, value: AuthService.getAuthToken()}]
    });

    this.uploader.onAfterAddingFile = (item: any) => {
      this.imageLoading = true;
      item.upload();
    };

    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      const newProfileImage = new Image(JSON.parse(response));

      const userProfileForSaving = this.createImageSubmitData(newProfileImage.id);

      // start polling for picture (profile)
      this.userService.changeUserProfile(this.userProfile.id, userProfileForSaving).subscribe(userDataResponse => {
        setTimeout(this.pollForImage(), 100);
      });
    };
  }

  pollForImage() {
    return () => {
      this.userService.getUserProfile(this.authService.getCurrentUser().id).subscribe(userProfileData => {
        const image = new Image(userProfileData.image);
        if (image.small) {
          this.toastService.success('Pilt salvestatud!');
          this.userProfile.image = image;
          this.imageLoading = false;
        } else {
          setTimeout(this.pollForImage(), 200);
        }
      }, err => {
        this.toastService.error('Pildi salvestamine ebaõnnestus!');
      });
    };

  }

  initPasswordForm() {
    const controlsConfig = {
      newPassword: ['', [Validators.required, InputValidators.lengthValidator(6, 24)]],
      newPasswordRepeat: ['', [Validators.required, InputValidators.lengthValidator(6, 24)]]
    };

    if (this.authService.getCurrentUser().hasPassword) {
      controlsConfig['currentPassword'] = ['', Validators.required];
    }

    this.changePasswordForm = this.formBuilder.group(controlsConfig, {validator: FormValidators.validateFormPassword.bind(this)});
  }

  onEditPasswordSubmit() {
    this.passwordFormSubmitted = true;
    if (this.submitEditPasswordInProgress || this.changePasswordForm.invalid) {
      return;
    }

    this.submitEditPasswordInProgress = true;

    let currentPassword = '';
    if (this.passwordForm.currentPassword) {
      currentPassword = this.passwordForm.currentPassword.value;
    }

    this.userService.changePassword(currentPassword, this.passwordForm.newPassword.value).subscribe(userDataResponse => {
      this.toastService.success('Parool muudetud!');

      // sets new user data so that the password form would display correct
      this.authService.setCurrentUserFromResponse(userDataResponse);
      this.currentUser = this.authService.getCurrentUser();
      this.resetPasswordForm();
      this.editPasswordModal.hide();
      this.submitEditPasswordInProgress = false;

    }, error => {
      if (error.errors.password) {
        this.oldPasswordWrong = true;
      }
      this.submitEditPasswordInProgress = false;
    });
  }

  showFillPasswordFields(controlName: string): boolean {
    const control = this.getPasswordForm[controlName];
    return !!((control.touched && control.dirty || this.passwordFormSubmitted) && control.errors && control.errors.required);
  }

  showPasswordMismatchError() {
    return (
      this.passwordForm.newPassword.touched && this.passwordForm.newPassword.dirty &&
      this.passwordForm.newPasswordRepeat.touched && this.passwordForm.newPasswordRepeat.dirty &&
      this.changePasswordForm.errors
    );
  }

  showNewPasswordLengthError() {
    return (
      this.passwordForm.newPassword.touched && this.passwordForm.newPassword.dirty &&
      this.passwordForm.newPassword.errors && this.passwordForm.newPassword.errors.length
    );
  }

  resetOldPasswordError() {
    this.oldPasswordWrong = false;
  }

  resetPasswordForm() {
    this.initPasswordForm();
    this.changePasswordForm.reset();
    this.passwordFormSubmitted = false;
    this.oldPasswordWrong = false;
  }

  initEmailForm() {
    const controlsConfig = {
      changeEmailEmail: ['', [Validators.email, Validators.required]],
      changePasswordEmail: ['', [Validators.required]]
    };

    this.changeEmailForm = this.formBuilder.group(controlsConfig);
  }

  onEmailFormSubmit() {
    this.changeEmailFormSubmitted = true;
    if (this.submitEditEmalInProgress || this.changeEmailForm.invalid) {
      return;
    }

    this.submitEditEmalInProgress = true;

    this.userService.changeEmail(
      this.getEmailForm.changePasswordEmail.value,
      this.getEmailForm.changeEmailEmail.value)
      .subscribe(userDataResponse => {
        this.toastService.success('E-maili muutmise kinnitus saadetud!');

        // sets new user data so that the password form would display correct
        this.authService.setCurrentUserFromResponse(userDataResponse);
        this.currentUser = this.authService.getCurrentUser();
      }, error => {
        this.submitEditEmalInProgress = false;
        if (error.errors.password) {
          this.emailFormPasswordWrong = true;
        }
      }, () => {
        this.submitEditEmalInProgress = false;
        this.resetEmailForm();
        this.editEmailModal.hide();
      });
  }

  resetEmailForm() {
    this.initEmailForm();
    this.changeEmailForm.reset();
    this.changeEmailFormSubmitted = false;
    this.emailFormPasswordWrong = false;
  }

  isDirtyAndInvalid(controlName: string): boolean {
    const control = this.getEmailForm[controlName];
    return !!((control.touched && control.dirty || this.changeEmailFormSubmitted) && control.errors);
  }

  initUserProfileForm() {
    this.userProfileForm = this.formBuilder.group({
      userProfileFirstname: [this.currentUser.firstName, [Validators.required]],
      userProfileLastname: [this.currentUser.lastName, [Validators.required]],
      userProfileLanguages: ['', [Validators.required]],
      userProfileRegions: ['', []],
      userProfileOccupation: [this.currentUser.profession, []],
      userProfileVocationCertificate: [this.currentUser.certificateNumber, []],
      userProfilePhone: [this.currentUser.phoneNumber, []],
      userProfileEmail: [
        (this.currentUser.publicEmail ? this.currentUser.publicEmail : this.currentUser.email),
        [Validators.required, Validators.email]],
      userProfileDescription: ['', []],
    });
  }

  resetUserProfileForm() {
    this.userProfileForm.reset();
    this.initUserProfileForm();
    this.populateUserProfileForm();
    this.userProfileFormSubmitted = false;
  }

  showFillUserProfileFields(controlName: string): boolean {
    const control = this.getUserProfileForm[controlName];
    return !!((control.touched && control.dirty || this.userProfileFormSubmitted) && control.errors && control.errors.required);
  }

  showUserProfileErrors(controlName: string): boolean {
    const control = this.getUserProfileForm[controlName];
    return !!((control.touched && control.dirty || this.userProfileFormSubmitted) && control.errors);
  }

  onUserProfileSubmit() {
    const that = this;

    this.userProfileFormSubmitted = true;
    if (this.submitProfileInProgress || this.userProfileForm.invalid) {
      return;
    }
    this.submitProfileInProgress = true;

    // changes User's field first
    this.userService.changeUser(this.currentUser.id, this.createUserSubmitData()).subscribe(userDataResponse => {

      // changes UserProfile fields second
      this.userService.changeUserProfile(this.userProfile.id, this.createProfileSubmitData()).subscribe(userProfileDataResponse => {
        this.toastService.success('Profiil salvestatud!');
        this.authService.setCurrentUserFromResponse(userProfileDataResponse.user);
        this.currentUser = this.authService.getCurrentUser();
        this.userProfile.image = new Image(userProfileDataResponse.image);
      }, error => {
        this.submitProfileInProgress = false;
      }, () => {
        this.submitProfileInProgress = false;
        this.resetUserProfileForm();
      });

    }, error => {
      this.submitProfileInProgress = false;

    }, () => {

    });
  }

  createUserSubmitData() {
    return {
      'certificateNumber': this.getUserProfileForm['userProfileVocationCertificate'].value,
      'publicEmail': this.getUserProfileForm['userProfileEmail'].value,
      'email': this.currentUser.email,
      'firstName': this.getUserProfileForm['userProfileFirstname'].value,
      'lastName': this.getUserProfileForm['userProfileLastname'].value,
      // 'password': this.getUserProfileForm['certificateNumber'].value,
      'phoneNumber': this.getUserProfileForm['userProfilePhone'].value,
      'profession': this.getUserProfileForm['userProfileOccupation'].value,
    };
  }

  createProfileSubmitData() {
    return {
      'regions': this.userProfile.regions,
      'adverts': [],
      'description': {
        'en': '',
        'et': this.getUserProfileForm['userProfileDescription'].value,
        'fi': '',
        'lv': '',
        'ru': ''
      },
      'image': (this.userProfile.image ? this.userProfile.image.id : null),
      'languages': this.getUserProfileForm['userProfileLanguages'].value,
      'videos': []
    };
  }

  createImageSubmitData(imageId) {
    return {
      'regions': this.userProfile.regions,
      'adverts': [],
      'description': {
        'en': '',
        'et': this.userProfile.description.et,
        'fi': '',
        'lv': '',
        'ru': ''
      },
      'image': imageId,
      'languages': this.userProfile.languages,
      'videos': []
    };
  }

  public handleAddressChange(address: Address) {
    const region = this.createRegion(address);
    this.ngxGooglePlacesInput.nativeElement.value = null;
    this.userProfile.regions.push(region);
  }

  removeRegion(regionId, index) {
    this.userProfile.regions = this.userProfile.regions.filter((region, i) => {
      return index !== i;
    });

  }

  createRegion(address: Address) {
    const that = this;
    const region = {
      administrativeAreaLevel1: '',
      administrativeAreaLevel2: '',
      country: '',
      id: null,
      locality: '',
      name: address.name,
      neighborhood: '',
      sublocalityLevel1: '',
    };

    this.parseAddressComponentsIntoRegion(address.address_components, region);

    const lat: any = address.geometry.location.lat();
    const lng: any = address.geometry.location.lng();
    const geocoder = new google.maps.Geocoder;
    const latlng = {lat: parseFloat(lat), lng: parseFloat(lng)};
    geocoder.geocode({'location': latlng}, function (results: any, status: any) {
      if (status === 'OK') {
        if (results && results.length > 0) {
          for (let i = 0; i < results.length; i++) {
            if (results[i].address_components) {
              that.parseAddressComponentsIntoRegion(results[i].address_components, region);
            }
          }
        }
      } else {
        that.toastService.error('Regiooni lisamisel tekkis viga!');
      }
    });

    return region;
  }

  parseAddressComponentsIntoRegion(addressComponents, region) {
    for (const i of Object.keys(addressComponents)) {
      const type = addressComponents[i]['types'][0];
      const value = addressComponents[i]['long_name'];

      switch (type) {
        case 'locality':
          region.locality = value;
          break;
        case 'administrative_area_level_1':
          region.administrativeAreaLevel1 = value;
          break;
        case 'administrative_area_level_2':
          region.administrativeAreaLevel2 = value;
          break;
        case 'country':
          region.country = value;
          break;
        case 'neighborhood':
          region.neighborhood = value;
          break;
        case 'sublocality_level_1':
          region.sublocalityLevel1 = value;
          break;
      }
    }
  }

  activateGoogle(): void {
    this.socialLoginAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then(socialUser => {
      this.authService.googleActivate(socialUser.id, socialUser.authToken).subscribe(res => {
        this.toastService.success('Ühendamine õnnestus!');
        this.authService.setCurrentUser(new User(res));
        this.currentUser = this.authService.getCurrentUser();
      }, error1 => {
        this.toastService.error('Ühendamine ebaõnnestus!');
      });
    });
  }

  activateFB(): void {
    this.socialLoginAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then(socialUser => {
      this.authService.facebookActivate(socialUser.id, socialUser.authToken).subscribe(res => {
        this.toastService.success('Ühendamine õnnestus!');
        this.authService.setCurrentUser(new User(res));
        this.currentUser = this.authService.getCurrentUser();
      }, error1 => {
        this.toastService.error('Ühendamine ebaõnnestus!');
      });
    });
  }
}
