import {Component, OnInit, ViewChild} from '@angular/core';
import {ModalDirective} from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-mock-advert-modal',
  templateUrl: './mock-advert-modal.component.html'
})
export class MockAdvertModalComponent implements OnInit {

  @ViewChild('mockAdvertModal') mockAdvertModal: ModalDirective;
  constructor() { }

  ngOnInit() {
  }

  show() {
    this.mockAdvertModal.show();
  }

}
