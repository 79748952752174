export class DomainValueGroup {
  code: string;
  sortOrder: number;
  translations: any;

  constructor(json?: any) {
    if (!json) {
      return;
    }
    this.code = json.code;
    this.sortOrder = json.sortOrder;
    this.translations = json.translations;
  }
}
