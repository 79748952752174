import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AdvertListComponent} from './advert/list/list.component';
import {AdvertDetailComponent} from './advert/detail/detail.component';
import {AdvertEditComponent} from './advert/edit/edit.component';
import {LoginComponent} from './login/login/login.component';
import {UserProfileComponent} from './user/user-profile/user-profile.component';
import {CompanyProfileComponent} from './user/company-profile/company-profile.component';
import {NotificationsComponent} from './user/notifications/notifications.component';
import {SimpleLayoutComponent} from './layout/simple-layout/simple-layout.component';
import {FullLayoutComponent} from './layout/full-layout/full-layout.component';
import {LoggedInGuard} from './guard/logged-in.guard';
import {NotLoggedInGuard} from './guard/not-logged-in.guard';
import {ForgotPasswordComponent} from './login/forgot-password/forgot-password.component';
import {ConfirmEmailChangeComponent} from './user/confirm-email-change/confirm-email-change.component';

const routes: Routes = [

  {
    path: '', redirectTo: '/advert/list', pathMatch: 'full'
  },
  {
    path: 'confirm-email-change', component: SimpleLayoutComponent, children: [
      {path: ':hash/:currentEmail/:newEmail', component: ConfirmEmailChangeComponent},
    ]
  },
  {
    path: 'login', component: SimpleLayoutComponent, canActivate: [NotLoggedInGuard], children: [
      {path: '', component: LoginComponent},
      {path: 'reset-password/:hash', component: ForgotPasswordComponent},
    ]
  },
  {
    path: 'advert', component: FullLayoutComponent, canActivate: [LoggedInGuard], children: [
      {path: 'list', component: AdvertListComponent},
      {path: 'details/:id', component: AdvertDetailComponent},
      {path: 'edit/:id', component: AdvertEditComponent},
    ]
  },
  {
    path: 'user', component: FullLayoutComponent, canActivate: [LoggedInGuard], children: [
      {path: 'user-profile', component: UserProfileComponent},
      {path: 'company-profile', component: CompanyProfileComponent},
      {path: 'notifications', component: NotificationsComponent},
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
