import {FormGroup} from '@angular/forms';

export class FormValidators {

  static validateFormPassword(formGroup: FormGroup) {
    const password = formGroup.controls.newPassword.value;
    const passwordRepeat = formGroup.controls.newPasswordRepeat.value;

    if (passwordRepeat !== password) {
      return {'noMatch': true};
    }
    return null;
  }

  static validateAddAdvertisement(formGroup: FormGroup) {
    const objectType = formGroup.controls.objectType.value;
    const cadasterNumber = formGroup.controls.cadasterNumber.value;
    const registerNumber = formGroup.controls.registerNumber.value;

    const areRequired = (objectType !== null &&
      objectType !== 'OBJECT_TYPE_APARTMENT' &&
      objectType.toString().substr(0, 16) !== 'OBJECT_TYPE_KOM_');

    if (areRequired && !cadasterNumber && !registerNumber) {
      return {'cadasterOrRegisterNumberRequired': true};
    }
    return null;
  }

}
