import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AdvertLite} from '../../class/advert-lite';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {AdvertService} from '../../service/advert/advert.service';

@Component({
  selector: 'app-publish-menu',
  templateUrl: './publish-menu.component.html',
  styleUrls: ['./publish-menu.component.scss']
})
export class PublishMenuComponent implements OnInit {

  @Input() ad: AdvertLite;
  @Output() hideMenus = new EventEmitter();

  companies = AdvertService.getCompaniesAsSelectElement();
  activeCompaniesForm: FormGroup;

  constructor(private formBuilder: FormBuilder,
              private advertService: AdvertService) { }

  ngOnInit() {
    this.initActiveCompaniesForm();
  }

  initActiveCompaniesForm() {
    this.activeCompaniesForm = this.formBuilder.group({});
    this.companies.forEach((company) => {
      this.activeCompaniesForm.addControl('activeCompany'.concat(company.value), new FormControl());
    });
  }

  hideSubMenus() {
    this.hideMenus.emit('hide');
  }

  activateAllCompanies(event) {
      Object.keys(this.activeCompaniesForm.controls).forEach(key => {
        this.activeCompaniesForm.get(key).setValue(event.target.checked);
      });
  }
}
