export class Image {
  id: number;
  advertImageId: number; // this variable is added for clarity and it is a copy of this.id
  imageId: number; // global image ID
  small: string;
  medium: string;
  large: string;
  thumbnail: string;
  dateCreated: Date;
  name: string;
  sortOrder: number;

  constructor(json?: any) {
    if (!json) {
      return;
    }

    this.id = json.id;
    this.advertImageId = json.id;
    this.imageId = json.imageId;
    this.small = json.small;
    this.medium = json.medium;
    this.thumbnail = json.thumbnail;
    this.large = json.large;
    this.dateCreated = new Date(json.dateCreated);
    this.sortOrder = json.sortOrder;
    this.name = json.name;

  }
}
