export class SmartIdResponse {
  errorCodes = {
    INVALID_PARAMETERS: 'Viga isikukoodiga.',
    USER_ACCOUNT_NOT_FOUND: 'Kasutajat ei leitud.',
    REQUEST_FORBIDDEN: 'Tehniline viga.',
    USER_REFUSED: 'Kasutaja loobus sisselogimisest.',
    SESSION_TIMEOUT: 'Tehniline viga.',
    DOCUMENT_UNUSABLE: 'Tehniline viga.',
    TECHNICAL_ERROR: 'Tehniline viga.',
    CLIENT_NOT_SUPPORTED: 'Tehniline viga.',
    SERVER_MAINTENANCE: 'Serveri viga.'
  };

  errorStatuses: any = ['ERROR', 'USER_NOT_FOUND'];

  statusErrors = {
    'ERROR': 'Tekkis viga',
    'USER_NOT_FOUND': 'Kasutajat ei leitud',
  };

  hasError = false;
  errorCode: number;
  status: string;
  challengeId: number;
  errorMessage: string;
  authToken: string;

  constructor(json?: any) {
    this.status = json.status;
    this.challengeId = json.verificationCode;
    this.errorCode = json.errorCode;
    if (this.errorCode !== null) {
      this.hasError = true;

      if (this.errorCodes[this.errorCode]) {
        this.errorMessage = this.errorCodes[this.errorCode];
      }

      if (!this.errorMessage) {
        this.errorMessage = 'Tundmatu viga';
      }
    }
  }

  checkStatusErrors() {
    if (this.errorStatuses.indexOf(this.status) > -1) {
      this.errorMessage = null;
      this.hasError = true;
      this.setStatusMessage();
      return true;
    } else {
      return false;
    }
  }

  setStatusMessage() {
    if (this.statusErrors[this.status]) {
      this.errorMessage = this.statusErrors[this.status];
    }

    if (!this.errorMessage) {
      this.errorMessage = 'Tundmatu viga';
    }
  }
}
