import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {User} from '../../class/user';
import {AuthService} from '../../service/auth/auth.service';
import {UserService} from '../../service/user/user.service';
import {UserProfile} from '../../class/user-profile';
import {ToastService} from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
})
export class NotificationsComponent implements OnInit {

  notificationsForm: FormGroup;
  currentUser: User;
  userProfile: UserProfile;

  constructor(private formBuilder: FormBuilder,
              private authService: AuthService,
              private toastService: ToastService,
              private userService: UserService) {
  }

  get getNotificationsForm() {
    return this.notificationsForm.controls;
  }

  ngOnInit() {
    this.currentUser = this.authService.getCurrentUser();
    this.initNotificationsForm();
    this.initFormOnChanges();
    this.showProfileImage();
  }

  initNotificationsForm() {
    this.notificationsForm = this.formBuilder.group({
      showBookingEndNearEmail: [false, [Validators.required]],
      showBookingEndNearPhone: [false, [Validators.required]],
      showContractEndNearEmail: [false, [Validators.required]],
      showContractEndNearPhone: [false, [Validators.required]],
      showSummariesEmail: [false, [Validators.required]],
      showSummariesPhone: [false, [Validators.required]],
      showMarketOverviewEmail: [false, [Validators.required]],
      showMarketOverviewPhone: [false, [Validators.required]],
    });
  }

  initFormOnChanges(): void {
    const that = this;

    Object.keys(this.getNotificationsForm).forEach(function (controlName) {
      that.getNotificationsForm[controlName].valueChanges.subscribe(val => {
        that.saveNotificationSetting(controlName, val);
      });
    });
  }

  showProfileImage() {
    this.userService.getUserProfile(this.authService.getCurrentUser().id).subscribe(userProfileData => {
      this.userProfile = new UserProfile(userProfileData);
    }, _ => {
      this.toastService.error('Pildi laadimine ebaõnnestus!');
    });
  }

  saveNotificationSetting(name, value) {
    // todo: change this later according to endpoint
    // this.userService.changeNotificationSetting(name, value).subscribe();
  }

}
