import {Component, OnInit} from '@angular/core';
import {UserService} from '../../service/user/user.service';
import {Organization} from '../../class/organization';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastService} from 'ng-uikit-pro-standard';
import {FileUploader} from 'ng2-file-upload';
import {AuthService} from '../../service/auth/auth.service';
import {Image} from '../../class/image';
import {UserProfileComponent} from '../user-profile/user-profile.component';
import {Config} from '../../config/config';
import {UserProfile} from '../../class/user-profile';

@Component({
  selector: 'app-company-profile',
  templateUrl: './company-profile.component.html',
})
export class CompanyProfileComponent implements OnInit {

  currentOrganization: Organization;
  userProfile: UserProfile;

  changeOrganizationForm: FormGroup;
  changeOrganizationFormSubmitted = false;
  submitOrgInProgress = false;

  uploader: FileUploader;
  imageLoading = false;

  constructor(private formBuilder: FormBuilder,
              private toastService: ToastService,
              private config: Config,
              private authService: AuthService,
              private userService: UserService) {
  }

  get getOrganizationForm() {
    return this.changeOrganizationForm.controls;
  }

  ngOnInit() {
    this.initChangeOrganizationForm();
    this.initImageUpload();
    this.userService.getOrganization().subscribe(resp => {
      this.currentOrganization = new Organization(resp);
      this.getOrganizationForm.name.setValue(this.currentOrganization.name);
      this.getOrganizationForm.registryCode.setValue(this.currentOrganization.registryCode);
      this.getOrganizationForm.address.setValue(this.currentOrganization.address);
      this.getOrganizationForm.url.setValue(this.currentOrganization.url);
      this.getOrganizationForm.phone.setValue(this.currentOrganization.phone);
      this.getOrganizationForm.email.setValue(this.currentOrganization.email);
    });
  }

  onOrganizationFormSubmit() {
    const that = this;
    let submitData = {};

    this.changeOrganizationFormSubmitted = true;
    if (this.submitOrgInProgress || this.changeOrganizationForm.invalid) {
      return;
    }
    this.submitOrgInProgress = true;

    submitData = {
      'address': this.getOrganizationForm['address'].value,
      'email': this.getOrganizationForm['email'].value,
      'name': this.getOrganizationForm['name'].value,
      'phone': this.getOrganizationForm['phone'].value,
      'registryCode': this.getOrganizationForm['registryCode'].value,
      'url': this.getOrganizationForm['url'].value,
      'image': this.currentOrganization.image.id
    };

    this.userService.changeOrganizationProfile(submitData).subscribe(userDataResponse => {
      this.toastService.success('Profiil salvestatud!');
    }, error => {
      this.submitOrgInProgress = false;
      this.toastService.error('Profiil salvestamine ebaõnnestus!');

    }, () => {
      this.submitOrgInProgress = false;
    });
  }

  initChangeOrganizationForm() {
    const controlsConfig = {
      name: ['', [Validators.required]],
      registryCode: ['', [Validators.required]],
      address: ['', [Validators.required]],
      url: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
    };

    this.changeOrganizationForm = this.formBuilder.group(controlsConfig);
  }

  isDirtyAndInvalid(controlName: string): boolean {
    const control = this.getOrganizationForm[controlName];
    return !!((control.touched && control.dirty || this.changeOrganizationFormSubmitted) && control.errors);
  }

  initImageUpload() {
    this.uploader = new FileUploader({
      url: this.config.getApiUrl(UserProfileComponent.UPLOAD_IMAGE_URL),
      headers: [{name: AuthService.X_AUTH_TOKEN, value: AuthService.getAuthToken()}]
    });

    this.uploader.onAfterAddingFile = (item: any) => {
      this.imageLoading = true;
      item.upload();
    };

    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      const newProfileImage = new Image(JSON.parse(response));

      // get currently saved info and use it to save the new image
      this.userService.getOrganization().subscribe(organizationDataResponse => {
        organizationDataResponse['image'] = newProfileImage.id;

        this.userService.changeOrganizationProfile(organizationDataResponse).subscribe(unusedOrgData => {
          setTimeout(this.pollForImage(), 100);
        }, error => {
          this.imageLoading = false;
          this.toastService.error('Pildi salvestamine ebaõnnestus!');
        });
      }, error => {
        this.imageLoading = false;
        this.toastService.error('Pildi salvestamine ebaõnnestus!');
      });
      return;
    };
  }

  pollForImage() {
    return () => {
      this.userService.getOrganization().subscribe(organizationData => {
        const image = new Image(organizationData.image);
        if (image.small) {
          this.toastService.success('Pilt salvestatud!');
          this.currentOrganization.image = image;
          this.imageLoading = false;
        } else {
          setTimeout(this.pollForImage(), 200);
        }
      }, err => {
        this.imageLoading = false;
        this.toastService.error('Pildi salvestamine ebaõnnestus!');
      });
    };
  }
}
