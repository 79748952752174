import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AuthService} from '../../service/auth/auth.service';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Error} from '../../class/error';
import {Config} from '../../config/config';
import {DomainValueService} from '../../service/domain-value/domain-value.service';
import {
  AuthService as SocialLoginAuthService,
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialUser
} from 'angularx-social-login';
import {MobileIdResponse} from '../../class/mobile-id';
import {UserService} from '../../service/user/user.service';
import {User} from '../../class/user';
import {ModalDirective, ToastService} from 'ng-uikit-pro-standard';
import {SmartIdResponse} from '../../class/smart-id';
import ReCaptcha = ReCaptchaV2.ReCaptcha;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  mobilePhoneNumber = '+372';
  mobileIdPoller; // this is the number/id of the poller created by setInterval()
  mobileIdResponse: MobileIdResponse;
  mobilIdConfig = this.config.getMobileIdConfig();
  pollInterval = this.mobilIdConfig.firstPollInterval;

  smartIdPoller; // this is the number/id of the poller created by setInterval()
  smartIdResponse: SmartIdResponse;
  smartIdConfig = this.config.getSmartIdConfig();
  smartIdPollInterval = this.smartIdConfig.firstPollInterval;
  userIdCode = '';

  email = '';
  password: string;
  showLogScreen = true;
  showForgotPasswordScreen = false;
  showRegisterScreen = false;
  showMobileLoginScreen = false;
  showSmartIdLoginScreen = false;

  registerForm: FormGroup;
  registerFormSubmitted = false;
  submitInProgress = false;

  @ViewChild('tosModal') tosModal: ModalDirective;
  @ViewChild('captchaRefRegister') registerReCaptcha: ReCaptcha;
  reCaptchaToken = '';

  loginInProgress = false;

  emailDuplicate = false;
  emailInvalid = false;
  captchaLogin = {};
  captchaRegister = {};
  siteKey = '';
  private user: SocialUser;
  private loggedIn: boolean;

  constructor(private authService: AuthService,
              private socialLoginAuthService: SocialLoginAuthService,
              private router: Router,
              private formBuilder: FormBuilder,
              private toastService: ToastService,
              private config: Config,
              private userService: UserService,
              private domainValueService: DomainValueService) {
    this.siteKey = Config.getGoogleReCaptchaKey();
  }

  get getRegisterForm() {
    return this.registerForm.controls;
  }

  login() {
    if (this.loginInProgress) {
      return;
    }
    this.loginInProgress = true;

    if (!this.email || !this.password) {
      this.toastService.success('Palun sisesta e-mailiaadress ja parool');
      this.loginInProgress = false;
      return;
    }
    // currentUser and token are set in authService
    this.authService.login(this.email, this.password).subscribe(
      result => {
        if (this.authService.getCurrentUser()) {
          this.domainValueService.setDomainValuesToLocalStorage().subscribe(_ => {
            this.loginInProgress = false;
            this.router.navigate(['']);
          });
        }
      },
      error => {
        this.loginInProgress = false;
        if (error.status === 401) {
          this.toastService.error('Kontrolli üle sisestatud andmed!');
        } else {
          this.toastService.error('Sisselogimisel tekkis probleem. Palun teavita administraatorit.');
        }
      });
  }

  showRegistrationScreen(event, showRegistrationScreen: boolean) {
    event.preventDefault();
    if (showRegistrationScreen) {
      this.getRegisterForm.registerEmail.setValue(this.email);
      this.switchView('register');
    } else {
      this.email = this.getRegisterForm.registerEmail.value;
      this.switchView('login');
    }
  }

  showForgotPasswordView(event) {
    event.preventDefault();
    this.switchView('forgotPassword');
  }

  ngOnInit() {
    const regFormControlsConfig = {
      name: ['', [Validators.required]],
      registerEmail: ['', [Validators.required, Validators.email]],
      companyName: [''],
      tos: ['', [Validators.requiredTrue]],
      canSendNews: []
    };
    this.registerForm = this.formBuilder.group(regFormControlsConfig);
  }

  startRegisterProcess() {
    if (!this.reCaptchaToken) {
      this.registerReCaptcha.execute();
    } else {
      this.register();
    }
  }

  register(reCaptchaString = null) {
    if (!this.reCaptchaToken) {
      this.reCaptchaToken = reCaptchaString;
    }

    this.registerFormSubmitted = true;

    if (this.submitInProgress || this.registerForm.invalid) {
      return;
    }

    this.submitInProgress = true;

    // currentUser and token are set in authService
    this.authService.register(
      this.getRegisterForm.name.value,
      this.getRegisterForm.registerEmail.value,
      this.getRegisterForm.companyName.value,
      this.getRegisterForm.canSendNews.value,
      this.reCaptchaToken).subscribe(
      result => {
        if (this.authService.getCurrentUser()) {
          this.domainValueService.setDomainValuesToLocalStorage().subscribe(_ =>
            this.router.navigate(['/user/user-profile'], { queryParams: { redirect: 'pw-modal'} }));
        }
        this.submitInProgress = false;
      },
      error => {
        this.resetEmailErrors();
        grecaptcha.reset(); // resets recaptcha
        this.reCaptchaToken = null;
        if (error.errors.email) {
          if (error.errors.email === Error.errorDuplicate) {
            this.emailDuplicate = true;
          } else if (error.errors.email === Error.errorInvalid) {
            this.emailInvalid = true;
          }
        }
        this.submitInProgress = false;
      });
  }

  isDirtyAndInvalid(controlName: string): boolean {
    const control = this.getRegisterForm[controlName];
    return !!((control.touched && control.dirty || this.registerFormSubmitted) && control.errors);
  }

  isDirtyAndInvalidCheckbox(controlName: string): boolean {
    const control = this.getRegisterForm[controlName];
    return !!((control.dirty || this.registerFormSubmitted) && control.errors);
  }

  resetEmailErrors() {
    this.emailDuplicate = false;
    this.emailInvalid = false;
  }

  signInWithGoogle(): void {
    this.socialLoginAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then(socialUser => {
      this.authService.googleLogin(socialUser.id, socialUser.authToken).subscribe();
    });
  }

  signInWithFB(): void {
    this.socialLoginAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then(socialUser => {
      this.authService.facebookLogin(socialUser.id, socialUser.authToken).subscribe();
    });
  }

  signInWithIdCard(): void {
    this.authService.idCardLogin().subscribe(rep => {
        this.postMobileSmartIdCardLoginActions();
      },
      err => {
        try {
          const res = err;
          if (res.status === 'USER_NOT_FOUND') {
            this.toastService.error('Kasutajat ei leitud.');
          } else if (res.status === 'BAD_CERTIFICATE') {
            this.toastService.error('Viga sertifikaadiga.');
          } else {
            this.toastService.error('Sisselogimine ebaõnnestus');
          }
        } catch (e) {
          this.toastService.error('Sisselogimine ebaõnnestus');
        }
      });
  }

  ngOnDestroy() {
    this.clearMobileIdPoller();
  }

  signOut(): void {
    this.socialLoginAuthService.signOut();
  }

  showFilledRegisterForm(user: SocialUser): void {
    this.switchView('register');
    if (user.email) {
      this.getRegisterForm.registerEmail.setValue(user.email);
    }
    if (user.name) {
      this.getRegisterForm.name.setValue(user.name);
    }
  }

  switchView(activeView) {
    this.showLogScreen = false;
    this.showRegisterScreen = false;
    this.showForgotPasswordScreen = false;
    this.showMobileLoginScreen = false;
    this.showSmartIdLoginScreen = false;

    switch (activeView) {
      case 'register':
        this.showRegisterScreen = true;
        break;
      case 'login':
        this.showLogScreen = true;
        break;
      case 'forgotPassword':
        this.showForgotPasswordScreen = true;
        break;
      case 'mobileLogin':
        this.showMobileLoginScreen = true;
        break;
      case 'smartIdLogin':
        this.showSmartIdLoginScreen = true;
        break;
    }
  }

  sendPasswordEmail() {
    this.userService.requestPasswordLink(this.email).subscribe(response => {
      this.toastService.success('E-mail saadetud!');
    }, errorResponse => {
      let errorMessage = 'Viga';
      if (errorResponse.status === 404) {
        if (errorResponse.error.code === Error.errorNotFound) {
          errorMessage = 'Sellise e-mailiga kasutajat ei leitud';
        } else {
          errorMessage = 'Kasutaja on arhiveeritud';
        }
      } else if (errorResponse.status === 400) {
        errorMessage = 'Kontrolli e-maili';
      }

      this.toastService.error(errorMessage);
    });
  }

  /* mobileID */
  singInWithMobileId() {
    this.authService.authMobileId(this.mobilePhoneNumber).subscribe(
      (res) => {
        this.setMobileResponse(res, true);
        return;
      },
      (err) => {
        this.clearMobileIdPoller();
        if (err['status'] === 400) {

        } else {
          this.toastService.error('Sisselogimine ebaõnnestus.');
        }
      }
    );
  }

  postMobileSmartIdCardLoginActions() {
    this.userService.me().subscribe(data => {
        const user = new User(data.user);
        this.authService.setCurrentUser(user);
        if (this.authService.getCurrentUser()) {
          this.domainValueService.setDomainValuesToLocalStorage().subscribe(_ => this.router.navigate(['']));
        }
      },
      err => {
        this.toastService.error('Sisselogimisel tekkis probleem');
      });
  }

  private setMobileResponse(res: MobileIdResponse, firstResponse: boolean = true) {
    this.mobileIdResponse = res;

    if (this.mobileIdResponse.errorCode !== 0) {
      this.clearMobileIdPoller(false);

    } else if (this.mobileIdResponse.status === 'USER_AUTHENTICATED') {
      this.clearMobileIdPoller(false);
      if (AuthService.getAuthToken()) {
        this.postMobileSmartIdCardLoginActions();
      }

    } else if (this.mobileIdResponse.checkStatusErrors()) {
      this.clearMobileIdPoller(false);

    } else if (firstResponse) {
      this.pollMobileAuth();
    }
  }

  private pollMobileAuth() {
    // starts polling if user has input correct PIN
    this.mobileIdPoller = setInterval(() => {
      this.authService.authMobileId(this.mobilePhoneNumber).subscribe(
        (res) => {
          this.pollInterval = this.config.getConfig().mobileId.pollInterval;
          this.setMobileResponse(res, false);
        },
        (err) => {
          this.toastService.error(err);
          clearInterval(this.mobileIdPoller);
        }
      );
    }, this.pollInterval);
  }

  private clearMobileIdPoller(withResponse: boolean = true) {
    this.pollInterval = this.config.getConfig().mobileId.firstPollInterval;
    if (withResponse) {
      this.mobileIdResponse = null;
    }
    if (this.mobileIdPoller) {
      clearInterval(this.mobileIdPoller);
    }
  }

  /* smartId */

  singInWithSmartId() {
    this.authService.authSmartId(this.userIdCode).subscribe(
      (res) => {
        this.setSmartIdResponse(res, true);
        return;
      },
      (err) => {
        this.clearSmartIdPoller();
        if (err['status'] === 400) {

        } else {
          this.toastService.error('Sisselogimine ebaõnnestus.');
        }
      }
    );
  }

  showTos(e) {
    // stop it from checking the checkbox
    e.preventDefault();
    this.tosModal.show();

  }

  private setSmartIdResponse(res: SmartIdResponse, firstResponse: boolean = true) {
    this.smartIdResponse = res;

    if (this.smartIdResponse.errorCode !== null) {
      this.clearSmartIdPoller(false);

    } else if (this.smartIdResponse.status === 'USER_AUTHENTICATED') {
      this.clearSmartIdPoller(false);
      if (AuthService.getAuthToken()) {
        this.postMobileSmartIdCardLoginActions();
      }

    } else if (this.smartIdResponse.checkStatusErrors()) {
      this.clearSmartIdPoller(false);

    } else if (firstResponse) {
      this.pollSmartIdAuth();
    }
  }

  private pollSmartIdAuth() {
    // starts polling if user has input correct PIN
    this.smartIdPoller = setInterval(() => {
      this.authService.authSmartId(this.userIdCode).subscribe(
        (res) => {
          this.smartIdPollInterval = this.config.getSmartIdConfig().pollInterval;
          this.setSmartIdResponse(res, false);
        },
        (err) => {
          this.toastService.error(err);
          clearInterval(this.smartIdPoller);
        }
      );
    }, this.smartIdPollInterval);
  }

  private clearSmartIdPoller(withResponse: boolean = true) {
    this.smartIdPollInterval = this.smartIdConfig.firstPollInterval;
    if (withResponse) {
      this.smartIdResponse = null;
    }
    if (this.smartIdPoller) {
      clearInterval(this.smartIdPoller);
    }
  }
}
