import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AdvertLite} from '../../../class/advert-lite';
import {FormBuilder} from '@angular/forms';
import {AdvertService} from '../../../service/advert/advert.service';
import {Advert} from '../../../class/advert';
import {Gallery, GalleryItem, ImageItem} from '@ngx-gallery/core';
import {DomainValue} from '../../../class/domain-value';
import {DomainValueService} from '../../../service/domain-value/domain-value.service';
import {ObjectValues} from '../../../class/object-values';
import {MockAdvertModalComponent} from '../../../component/mock-advert-modal/mock-advert-modal.component';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['../list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardComponent implements OnInit {

  @Input() ad: AdvertLite;
  @Output() copyAdEvent = new EventEmitter<Number>();
  @Output() archiveEvent = new EventEmitter<Number>();
  @Input() mockAdvertModal: MockAdvertModalComponent;

  displayPublishAdMenu = false;
  displayArchiveAdMenu = false;

  advert: Advert;
  galleryImageItems: GalleryItem[];
  detailsList = [];

  constructor(private formBuilder: FormBuilder,
              protected advertService: AdvertService,
              private domainValueService: DomainValueService,
              public gallery: Gallery) {
  }

  ngOnInit() {
    this.detailsList = this.createListDetails();
  }

  togglePublishAdMenu() {
    this.displayPublishAdMenu = !this.displayPublishAdMenu;
  }

  toggleArchiveAdMenu() {
    this.displayArchiveAdMenu = !this.displayArchiveAdMenu;
  }

  onArchiveHandler(adIdFromEvent) {
    this.archiveEvent.emit(adIdFromEvent);
  }

  hideSubMenus() {
    this.displayArchiveAdMenu = false;
    this.displayPublishAdMenu = false;
  }

  copyAd(ad: AdvertLite) {
    this.copyAdEvent.emit(ad.id);
  }

  openGallery() {
    this.openLoader();

    this.advertService.getAdvert(this.ad.id).subscribe(advertResponse => {
      const advert = new Advert(advertResponse);
      this.galleryImageItems = advert.images.map(image =>
        new ImageItem({
          src: image.large,
          thumb: image.small,
          medium: image.medium,
          sortOrder: image.sortOrder,
          imageId: image.id
        })
      );

      this.gallery.ref().load(this.galleryImageItems);
    });
  }

  openLoader() {
    this.galleryImageItems = [];
    const loaderImage = new ImageItem({
      src: 'assets/img/loading.gif',
      thumb: 'assets/img/loading.gif'
    });

    this.gallery.ref().load([loaderImage]);
  }

  createListDetails() {

    const objectTypeGroup = this.advertService.getObjectTypeGroupForAdvert(this.ad);
    const rawResult = {};
    const resultArray = [];

    if (objectTypeGroup === DomainValue.OBJECT_TYPE_LIVING) {
      rawResult['rooms'] = this.ad.rooms;
      rawResult['closedNetArea'] = this.ad.closedNetArea;

      if (this.ad.objectType === DomainValue.objectTypeApartment) {
        rawResult['floors'] = this.ad.getFloorsString();
      } else {
        rawResult['propertyArea'] = this.ad.propertyArea;
      }
    }

    if (objectTypeGroup === DomainValue.OBJECT_TYPE_KOM) {
      rawResult['rooms'] = this.ad.rooms;
      rawResult['closedNetArea'] = this.ad.closedNetArea;
      rawResult['floors'] = this.ad.getFloorsString();
    }

    if (objectTypeGroup === DomainValue.OBJECT_TYPE_KASI) {
      rawResult['objectType'] = this.domainValueService.getDomainValueTranslationByCodes(this.ad.objectType);
      rawResult['closedNetArea'] = this.ad.closedNetArea;
      rawResult['propertyArea'] = this.ad.propertyArea;
    }

    if (objectTypeGroup === DomainValue.OBJECT_TYPE_LAND) {
      rawResult['intendedUsages'] = this.domainValueService.getObjectValueTranslationByCodes(
        this.ad.intendedUsages, ObjectValues.OBJECT_INTENDED_USAGES);
      rawResult['propertyArea'] = this.ad.propertyArea;
    }

    // format into end result
    Object.keys(rawResult).forEach((key) => {
      if (!rawResult[key]) {
        return;
      }

      let resultValue: String = rawResult[key] + '';
      switch (key) {
        case 'rooms':
          resultValue = resultValue.concat(' tuba');
          break;
        case 'closedNetArea':
        case 'propertyArea':
          resultValue = resultValue.concat(' m²');
          break;
      }
      resultArray.push(resultValue);
    });

    return resultArray;
  }
}
