export class County {

  public static COUNTY_LIST_ET = [
    'Harju maakond',
    'Hiiu maakond',
    'Ida-Viru maakond',
    'Jõgeva maakond',
    'Järva maakond',
    'Lääne maakond',
    'Lääne-Viru maakond',
    'Põlva maakond',
    'Pärnu maakond',
    'Rapla maakond',
    'Saare maakond',
    'Tartu maakond',
    'Valga maakond',
    'Viljandi maakond',
    'Võru maakond'
  ];


  public static makeSelectData() {
    const data = [];
    for (let i = 0; i < County.COUNTY_LIST_ET.length; i++) {
      data.push({value: i, label: County.COUNTY_LIST_ET[i]});
    }

    return data;
  }
}
