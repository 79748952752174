import {Component, OnInit, ViewChild} from '@angular/core';
import {AdvertService} from '../../service/advert/advert.service';
import {IMyOptions, ToastService} from 'ng-uikit-pro-standard';
import {AdvertLite} from '../../class/advert-lite';
import {AdvertFilter} from '../../filter/advert-filter';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {InputValidators} from '../../validator/input-validators';
import {DomainValueService} from '../../service/domain-value/domain-value.service';
import {DomainValue} from '../../class/domain-value';
import {Router} from '@angular/router';
import {DataService} from '../../service/data/data.service';
import {Config} from '../../config/config';
import {AuthService} from '../../service/auth/auth.service';
import {MockAdvert} from '../../class/mock-advert';
import {MockAdvertModalComponent} from '../../component/mock-advert-modal/mock-advert-modal.component';


@Component({
  selector: 'app-advert-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class AdvertListComponent implements OnInit {

  @ViewChild('mockAdvertModal') mockAdvertModal: MockAdvertModalComponent;

  filterForm = new AdvertFilter();
  activityOptions = [['active', 'Aktiivsed'], ['published', 'Avaldatud'],
    ['notPublished', 'Mitte-avaldatud'], ['archived', 'Arhiveeritud']];

  adverts: AdvertLite[] = [];
  getAdvertsInProgress = false;
  canScrollMore = true;
  companySelectOptions = AdvertService.getCompaniesAsSelectElement();
  activitySelect = [];
  public datePickerOptions: IMyOptions = {};

  newAdvertForm: FormGroup;
  newAdvertFormSubmitted = false;
  newAdSubmitInProgress = false;
  selectedAddress = null;

  copyAdvertInProgress = false;

  objectTypeDomainValues = [];

  inAadress = null;
  knrUrl = '';

  constructor(public advertService: AdvertService,
              private formBuilder: FormBuilder,
              private domainValueService: DomainValueService,
              private router: Router,
              private config: Config,
              private toastService: ToastService,
              private authService: AuthService,
              private dataService: DataService) {
  }

  get getNewAdvertForm() {
    return this.newAdvertForm.controls;
  }

  ngOnInit() {
    const that = this;
    this.initNewAdvertForm();

    this.activityOptions.forEach((element, index) => {
      this.activitySelect.push({
        value: element[0],
        label: element[1]
      });
    });

    // sets domainValues to select element
    this.objectTypeDomainValues = this.domainValueService.getDomainValuesAsSelectOptions(DomainValue.OBJECT_TYPE, true);
    this.objectTypeDomainValues = this.domainValueService.mergeKomKasiObjectTypes(this.objectTypeDomainValues);
    if (this.authService.getCurrentUser().isNotActivated()) {
      this.adverts.push(new MockAdvert());
    }
    this.getMoreAdverts();

    // initializes inAddress
    const intervalId = setInterval(function () {
      if (document.getElementById('InAadressDiv')) {
        clearInterval(intervalId);
        // @ts-ignore
        that.inAadress = new InAadress({
          'container': 'InAadressDiv',
          mode: 3,
          results: 20,
          appartment: 1,
          unik: 0,
          nocss: true,
          searchLayers: ['EHITISHOONE', 'KATASTRIYKSUS'],
          namespace: 'inAdsList'
        });
      }
    });

    this.addInAddressEventListeners();
  }

  addInAddressEventListeners() {
    const that = this;

    // listens to addressLite selection in autocomplete field
    document.addEventListener('addressSelected.inAdsList', function (e) {
      // @ts-ignore
      that.inAadress.hideResult();
      // @ts-ignore
      that.setSelectedAddress(e.detail);
      // @ts-ignore
      that.inAadress.setAddress(e.detail.paadress);

      // @ts-ignore
      const adrId = e.detail.adr_id;

      // @ts-ignore
      const apartmentChosen = 'hoone_ads_oid' in e.detail;

      // if this equals true, then apartment has been just chosen
      if (apartmentChosen) {
        that.hideApartmentText();
      }

      const url = that.config.getConfig().inAdsUrl + adrId;

      that.dataService.getJSONPList(url).subscribe(resultBuildings => {
        if ('addresses' in resultBuildings) {
          let cadasterNumber = '';

          // @ts-ignore
          resultBuildings.addresses.forEach(addressData => {
            if (!cadasterNumber && addressData.liikVal === 'KATASTRIYKSUS') {
              cadasterNumber = addressData.tunnus;
            }
          });

          if (!apartmentChosen) {
            that.newAdvertForm.controls['newAdvertCadasterNumber'].setValue(cadasterNumber);
          }
        }

      });
    });

    document.addEventListener('inaadressLoaded.inAdsList', this.inAadressLoaded);

  }

  inAadressLoaded() {
    const rootDom = document.querySelector('#InAadressDiv');
    rootDom.querySelector('input').className += ' form-control';
    rootDom.querySelector('a[title="puhasta"]').remove();
  }

  hideApartmentText() {
    const rootDom = document.querySelector('#InAadressDiv');
    rootDom.querySelector('span.appartments').innerHTML = 'Korter';
  }

  // this is called when search string is changed
  filterQuery() {
    if (this.filterForm.canFilterByQuery()) {
      this.filter();
    }
  }

  createKnrUrl() {
    const cadasterNumber = this.newAdvertForm.controls.newAdvertCadasterNumber.value;
    if (this.getNewAdvertForm['newAdvertCadasterNumber'].errors) {
      this.knrUrl = null;
    } else {
      this.knrUrl = this.advertService.getKnrUrlFromCadasterNumber(cadasterNumber);
    }
  }

  filter() {
    if (!this.getAdvertsInProgress) {
      this.canScrollMore = true;
      this.adverts = [];
      this.filterForm.resetPageNumber();
      this.getMoreAdverts();
    }
  }

  getMoreAdverts() {
    if (this.getAdvertsInProgress || !this.canScrollMore) {
      return;
    }

    this.getAdvertsInProgress = true;
    this.advertService.getAdverts(this.filterForm.getHttpParams()).subscribe(
      (res: AdvertLite[]) => {
        this.adverts = this.adverts.concat(res);
        this.filterForm.newPage();
        this.getAdvertsInProgress = false;
        if (res.length === 0) {
          this.canScrollMore = false;
        }
      },
      (err) => {
        this.toastService.error('Tekkis viga kuulutuste laadimisel');
      }
    );
  }

  initNewAdvertForm() {
    this.newAdvertForm = this.formBuilder.group({
      newAdvertType: [null, [Validators.required]],
      newAdvertCadasterNumber: ['', [Validators.required, InputValidators.cadasterNumberValidator()]],
      newAdvertRegNumber: ['', []],
    });
  }

  showFillNewAdvertFields(controlName: string): boolean {
    if (!this.newAdvertForm) {
      return;
    }
    const control = this.getNewAdvertForm[controlName];
    return !!((control.touched && control.dirty || this.newAdvertFormSubmitted) && control.errors && control.errors.required);
  }

  showErrorAroundInput(controlName: string): boolean {
    if (!this.newAdvertForm) {
      return;
    }
    const control = this.getNewAdvertForm[controlName];
    return !!((control.touched && control.dirty || this.newAdvertFormSubmitted) && control.errors);
  }

  onNewAdvertSubmit() {
    this.newAdvertFormSubmitted = true;

    if (this.newAdSubmitInProgress || this.newAdvertForm.invalid || this.showAddressError()) {
      return;
    }
    this.newAdSubmitInProgress = true;

    const submitData = {
      'address': {
        'country': 'est',
        'externalId': this.selectedAddress.ads_oid,
        'sublocality': ''
      },
      'cadasterNumber': this.newAdvertForm.controls.newAdvertCadasterNumber.value,
      'registerNumber': this.newAdvertForm.controls.newAdvertRegNumber.value,
      'type': this.newAdvertForm.controls.newAdvertType.value
    };

    this.advertService.createNewAdvert(submitData).subscribe(userDataResponse => {
        this.router.navigate(['/advert/edit/', userDataResponse.id]);
      },
      error => {
        this.newAdSubmitInProgress = false;

      }, () => {
        this.newAdSubmitInProgress = false;
      });
  }

  showNewAdvertsError(controlName: string, errorName: string): boolean {
    return (
      this.getNewAdvertForm[controlName].errors && !this.getNewAdvertForm[controlName].errors.required &&
      (this.getNewAdvertForm[controlName].touched && this.getNewAdvertForm[controlName].dirty || this.newAdvertFormSubmitted) &&
      this.getNewAdvertForm[controlName].errors[errorName]);
  }

  showAddressError() {
    return this.newAdvertFormSubmitted && !this.selectedAddress;
  }

  setSelectedAddress(address) {
    this.selectedAddress = address;
  }

  copyAdvert(advertId) {
    if (advertId === 0) {
      this.mockAdvertModal.show();
      return;
    }

    if (this.copyAdvertInProgress) {
      return;
    }

    this.toastService.success('Toimub kuulutuse kopeerimine...');
    this.copyAdvertInProgress = true;

    this.advertService.getAdvert(advertId).subscribe(advert => {
      this.advertService.copyAdvert(advert).then(onFulfilled => {
        this.copyAdvertInProgress = false;
      }, onRejected => {
        this.toastService.error('Kuulutuse kopeerimisel juhtus viga');
        this.copyAdvertInProgress = false;
      });

    }, error => {
      this.toastService.error('Kuulutuse kopeerimisel juhtus viga');
      this.copyAdvertInProgress = false;
    });
  }

  onArchiveHandler(adIdFromEvent) {
    // filters out the archived advert
    this.adverts = this.adverts.filter(advert => {
      return advert.id !== adIdFromEvent;
    });
  }
}
