import {Injectable} from '@angular/core';
import {DataService} from '../data/data.service';

@Injectable({
  providedIn: 'root'
})
export class UpgradeService {

  public static SEND_UPGRADE_RECOMMENDATIONS_URL = 'upgrade/recommendation';

  constructor(private dataService: DataService) { }

  sendUpgradeRecommendations(recommendations: String[]) {
    return this.dataService.post(UpgradeService.SEND_UPGRADE_RECOMMENDATIONS_URL, {recommendation: recommendations});
  }
}
