import {Injectable} from '@angular/core';
import {DataService} from '../data/data.service';

@Injectable({
  providedIn: 'root'
})
export class ImageService {

  public static GET_ADVERT_IMAGES_URL = 'adverts/{id}/images';
  public static ADD_ADVERT_IMAGE_URL = 'adverts/{id}/images';
  public static DELETE_ADVERT_IMAGE = 'adverts/{id}/images/{advertImageId}';
  public static EDIT_IMAGE_SORT_ORDER = 'adverts/{id}/images/{advertImageId}';
  public static GET_ALL_IMAGES_URL = 'adverts/{id}/images';

  constructor(private dataService: DataService) { }

  getImagesForAdvert(advertId) {
    return this.dataService.getList(DataService.replaceId(advertId, ImageService.GET_ADVERT_IMAGES_URL));
  }

  addImageToAdvert(advertId, imageData) {
    return this.dataService.post(DataService.replaceId(advertId, ImageService.ADD_ADVERT_IMAGE_URL), imageData);
  }

  deleteImageFromAdvert(advertId, advertImageId) {
    let url = DataService.replaceId(advertId, ImageService.DELETE_ADVERT_IMAGE);
    url = DataService.replaceId(advertImageId, url, 'advertImageId');
    return this.dataService.delete(url);
  }

  editImageSortOrder(advertId, advertImageId, imageData) {
    let url = DataService.replaceId(advertId, ImageService.EDIT_IMAGE_SORT_ORDER);
    url = DataService.replaceId(advertImageId, url, 'advertImageId');
    return this.dataService.patch(url, imageData);
  }

  getAllImagesForAdvert(advertId) {
    const url = DataService.replaceId(advertId, ImageService.GET_ALL_IMAGES_URL);
    return this.dataService.getList(url);
  }
}
