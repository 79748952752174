export class Transaction {
  price: number = null;
  pricePerM2: number = null;
  propertyArea: number = null;

  constructor(json?: any) {
    if (!json) {
      return;
    }
    this.price = json.price;
    this.pricePerM2 = json.pricePerM2;
    this.propertyArea = json.propertyArea;
  }
}
