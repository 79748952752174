import {Component, Input, OnInit} from '@angular/core';
import {Advert} from '../class/advert';

@Component({
  selector: 'app-archived-advert-bar',
  templateUrl: './archived-advert-bar.component.html',
  styleUrls: ['./archived-advert-bar.component.scss']
})
export class ArchivedAdvertBarComponent implements OnInit {

  display = false;
  @Input() advert: Advert;

  constructor() {
  }

  ngOnInit() {
    this.display = this.advert.status === 'archived';
  }
}
