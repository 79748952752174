import {DomainValueGroup} from './domain-value-group';

export class DomainValue {

  // OBJECT TYPES
  public static objectTypeUnknown = 'OBJECT_TYPE_UNKNOWN';
  public static objectTypeHouse = 'OBJECT_TYPE_HOUSE';
  public static objectTypeApartment = 'OBJECT_TYPE_APARTMENT';
  public static objectTypeHouseShare = 'OBJECT_TYPE_HOUSE_SHARE';

  public static OBJECT_TYPE_KASI = 'OBJECT_TYPE_KASI';
  public static OBJECT_TYPE_KOM = 'OBJECT_TYPE_KOM';
  public static OBJECT_TYPE_LAND = 'OBJECT_TYPE_LAND';
  public static OBJECT_TYPE_LIVING = 'OBJECT_TYPE_LIVING';

  public static OBJECT_TYPE = 'OBJECT_TYPE';
  public static TRANSACTION_TYPE = 'TRANSACTION_TYPE';
  public static OBJECT_OWNERSHIP = 'OBJECT_OWNERSHIP';
  public static CUSTOM_FIELD_OBJECT_SUPPORTING_STRUCTURE = 'CUSTOM_FIELD_OBJECT_SUPPORTING_STRUCTURE';
  public static CUSTOM_FIELD_OBJECT_SANITARY_FURNITURE_STATE = 'CUSTOM_FIELD_OBJECT_SANITARY_FURNITURE_STATE';
  public static CUSTOM_FIELD_OBJECT_HEATING_SYSTEM = 'CUSTOM_FIELD_OBJECT_HEATING_SYSTEM';
  public static CUSTOM_FIELD_OBJECT_COLD_WATER = 'CUSTOM_FIELD_OBJECT_COLD_WATER';
  public static CUSTOM_FIELD_OBJECT_GAS_SUPPLY = 'CUSTOM_FIELD_OBJECT_GAS_SUPPLY';
  public static CUSTOM_FIELD_OBJECT_SEWERAGE = 'CUSTOM_FIELD_OBJECT_SEWERAGE';
  public static CUSTOM_FIELD_OBJECT_VENTILATION = 'CUSTOM_FIELD_OBJECT_VENTILATION';
  public static CUSTOM_FIELD_OBJECT_COMMUNICATIONS = 'CUSTOM_FIELD_OBJECT_COMMUNICATIONS';
  public static CUSTOM_FIELD_OBJECT_ALARM = 'CUSTOM_FIELD_OBJECT_ALARM';
  public static CUSTOM_FIELD_OBJECT_PARKING = 'CUSTOM_FIELD_OBJECT_PARKING';
  public static CUSTOM_FIELD_OBJECT_SANITARY = 'CUSTOM_FIELD_OBJECT_SANITARY';
  public static CUSTOM_FIELD_OBJECT_SECURITY = 'CUSTOM_FIELD_OBJECT_SECURITY';
  public static CUSTOM_FIELD_OBJECT_STOVE = 'CUSTOM_FIELD_OBJECT_STOVE';
  public static CUSTOM_FIELD_OBJECT_ENERGY_LABEL = 'CUSTOM_FIELD_OBJECT_ENERGY_LABEL';
  public static CUSTOM_FIELD_OBJECT_ROOF = 'CUSTOM_FIELD_OBJECT_ROOF';
  public static CUSTOM_FIELD_ADVERT_ADDITIONAL_INFO = 'CUSTOM_FIELD_ADVERT_ADDITIONAL_INFO';

  code: string;
  group: any;
  sortOrder: number;
  domain: string;
  translations: any;
  data: string;
  longValue: number;
  decimalValue: number;

  constructor(json?: any) {
    if (!json) {
      return;
    }
    this.code = json.code;
    this.translations = json.translations;
    this.sortOrder = json.sortOrder;
    this.domain = json.domain;
    this.data = json.data;
    this.longValue = json.longValue;
    this.decimalValue = json.decimalValue;
    if (json.group != null) {
      this.group = new DomainValueGroup(json.group);
    }
  }
}
