import {Component, OnInit, ViewChild} from '@angular/core';
import {AdvertService} from '../../service/advert/advert.service';

import {ActivatedRoute} from '@angular/router';
import {DomainValueService} from '../../service/domain-value/domain-value.service';
import {Advert} from '../../class/advert';
import {ObjectValues} from '../../class/object-values';
import {DomainValue} from '../../class/domain-value';
import {MatChipList} from '@angular/material';
import {Lightbox} from '@ngx-gallery/lightbox';
import {Gallery, GalleryItem, ImageItem} from '@ngx-gallery/core';
import * as Chart from 'chart.js';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {UserService} from '../../service/user/user.service';
import {ModalDirective, ToastService} from 'ng-uikit-pro-standard';
import {UpgradeService} from '../../service/upgrade/upgrade.service';
import {Transaction} from '../../class/transaction';
import {MockAdvert} from '../../class/mock-advert';
import {MockAdvertModalComponent} from '../../component/mock-advert-modal/mock-advert-modal.component';

@Component({
  selector: 'app-advert-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class AdvertDetailComponent implements OnInit {

  @ViewChild('matChipList') matChipList: MatChipList;
  optionsSelect: Array<any>;

  ad: Advert;
  typeSelect = ObjectValues.OBJECT_TYPE;
  intendedUsagesObjectValues = ObjectValues.OBJECT_INTENDED_USAGES;
  selectedLanguage = 'et';
  additionalInfoValues: DomainValue[];
  companies = AdvertService.getCompanies();
  similarTransactions: Transaction[];
  showUpgradeBlock = true;
  showSecondUpgradeBlock = true;

  knrUrl = '';
  ehrUrl = '';

  galleryImageItems: GalleryItem[];
  images;
  mockAdvert = false;

  @ViewChild('publishModal') publishModal: ModalDirective;
  @ViewChild('upgradeModal') upgradeModal: ModalDirective;
  @ViewChild('mockAdvertModal') mockAdvertModal: MockAdvertModalComponent;

  recommendationsForm: FormGroup;
  recommendations = [
    'Lisada ja hallata kasutajaid',
    'Lisada ja hallata klientide kontakte',
    'Tööprotsessi paremaks halduseks siduda omavahel objektide kontakte ja dokumente',
    'Statistilised võrdlused minu objektide ja kogu turuga'
  ];

  constructor(private route: ActivatedRoute,
              protected advertService: AdvertService,
              protected userService: UserService,
              protected toastService: ToastService,
              private lightbox: Lightbox,
              public gallery: Gallery,
              private formBuilder: FormBuilder,
              private upgradeService: UpgradeService,
              protected domainValueService: DomainValueService
  ) {
  }

  ngOnInit() {
    this.initEditAdvertForm();
    this.optionsSelect = [
      {value: 'et', label: 'Eesti keel'},
      {value: 'en', label: 'Inglise keel'},
      {value: 'ru', label: 'Vene keel'},
    ];
    const advertId = Number(this.route.snapshot.paramMap.get('id'));
    if (advertId === 0) {
      this.ad = new MockAdvert();
      this.knrUrl = this.advertService.getKnrUrl(this.ad);
      this.ehrUrl = this.advertService.getEhrUrl(this.ad);
      this.initModalGallery();
      this.mockAdvert = true;
    } else {
      this.advertService.getAdvert(advertId).subscribe(
        (res: Advert) => {
          this.ad = res;
          this.knrUrl = this.advertService.getKnrUrl(this.ad);
          this.ehrUrl = this.advertService.getEhrUrl(this.ad);
          this.initModalGallery();
          if (this.ad.similarTransactionsAddress) {
            this.advertService.getSimilarTransactions(this.ad).subscribe(similarTransactionsResult => {
              this.similarTransactions = similarTransactionsResult;
              this.initGraph();
            }, error => {
              this.toastService.error('Sarnaste tehingute graafiku koostamine ebaõnnestus!');
            });
          }
        },
        (err) => {
          this.toastService.error('Tekkis viga!');
        }
      );
    }
    this.additionalInfoValues = this.domainValueService.getDomainValuesByDomain(DomainValue.CUSTOM_FIELD_ADVERT_ADDITIONAL_INFO);
  }

  initModalGallery() {
    // imagesToGalleryItems
    this.galleryImageItems = this.ad.images.map(image =>
      new ImageItem({
        src: image.large,
        thumb: image.small,
        medium: image.medium,
        sortOrder: image.sortOrder,
        imageId: image.id
      })
    );

    this.gallery.ref().load(this.galleryImageItems);
  }

  initGraph() {
    const that = this;
    const data = this.parseTransactionData(this.similarTransactions);
    // runs after interval because element might not have been initalized
    const intervalId = setInterval(function () {
      if (document.getElementById('myChart')) {
        clearInterval(intervalId);
        const ctx = document.getElementById('myChart');
        // @ts-ignore
        const scatterChart = new Chart(ctx, {
          type: 'scatter',
          data: {
            datasets: [{
              pointBackgroundColor: 'blue',
              pointBorderColor: 'blue',
              pointRadius: 5,
              label: 'Sarnased kuulutused',
              data: data,
            },
            {
              pointBackgroundColor: 'orange',
              pointBorderColor: 'orange',
              label: 'Käesolev kuulutus',
              pointRadius: 5,
              data: [{
                x: that.ad.propertyArea,
                y: that.ad.price
              }]
            }]
          },
          options: {
            legend: {
              display: false
            },
            scales: {
              xAxes: [{
                type: 'linear',
                scaleLabel: {
                  labelString: 'Hind',
                  display: true,
                  position: 'left',
                },
                ticks: {
                  suggestedMin: 0,
                  callback: function(value, index, values) {
                    return value + ' €';
                  }
                },
              }],
              yAxes: [{
                type: 'linear',
                display: true,
                scaleLabel:  {
                  labelString: 'Pind',
                  display: true,
                  position: 'bottom',
                },
                ticks: {
                  suggestedMin: 0,
                  callback: function(value, index, values) {
                    return value + ' m²';
                  }
                }
              }]
            }
          }
        });
      }
    }, 100);
  }

  parseTransactionData(transactions: Transaction[]) {
    const result = [];
    transactions.forEach(transaction => {
      result.push({
        x: transaction.propertyArea,
        y: transaction.price
      });
    });

    return result;
  }

  initEditAdvertForm() {
    this.recommendationsForm = this.formBuilder.group({});
    this.recommendations.forEach((value, i) => {
      this.recommendationsForm.addControl('recommendation'.concat(i.toString()), new FormControl());
    });
  }

  onRecommendationsSubmit() {
    const resultArr = [];
    this.recommendations.forEach((value, i) => {
      if (this.recommendationsForm.controls['recommendation'.concat(i.toString())].value) {
        resultArr.push(this.recommendations[i]);
      }
    });

    if (resultArr.length === 0) {
      this.toastService.error('Vali mõni sobilik lause!');
      return;
    }

    this.upgradeService.sendUpgradeRecommendations(resultArr).subscribe(_ => {
      this.toastService.success('Tagasiside saadetud!');
      this.upgradeModal.hide();
    });
  }

  copyAdvert() {
    if (this.ad.isMockAdvert()) {
      this.mockAdvertModal.show();
      return;
    }

    this.toastService.success('Toimub kuulutuse kopeerimine...');
    this.advertService.copyAdvert(this.ad);
  }
}
