export class MobileIdResponse {
  errorCodes = {
    100: {
      et: 'Üldine viga.',
      en: 'General error.'
    },
    101: {
      et: 'Ebakorrektsed sisendparameetrid.',
      en: 'Incorrect input parameters.'
    },
    102: {
      et: 'Mõned sisendparameetrid puuduvad.',
      en: 'Some of required input parameters are missing.'
    },
    103: {
      et: 'Teenusepakkujal ei ole juurdepääsu SK kehtivuskinnituse teenusele (OCSP UNAUTHORIZED).',
      en: 'Service provider does not have access to SK validity confirmation service (OCSP response UNAUTHORIZED).'
    },
    200: {
      et: 'Üldine teenuse viga.',
      en: 'General error of the service.'
    },
    201: {
      et: 'Kasutaja sertifikaat puudub',
      en: 'Missing user certificate.'
    },
    202: {
      et: 'Ei olnud võimalik kontrollida sertifikaadi kehtivust.',
      en: 'Unable to verify certificate validity.'
    },
    203: {
      et: 'Session on lukustatud teise SOAP taotluse poolt.',
      en: 'Session is locked by the other SOAP request.'
    },
    300: {
      et: 'Üldine viga, mis on seotud kasutaja mobiiliga.',
      en: 'General error related to user\'s mobile phone.'
    },
    301: {
      et: 'Kasutaja ei ole Mobiili-ID klient.',
      en: 'Not a Mobile-ID user.'
    },
    302: {
      et: 'Kasutaja sertifikaat ei ole kehtiv (OCSP: REVOKED).',
      en: 'The certificate of the user is not valid (OCSP said: REVOKED).'
    },
    303: {
      et: 'Sertifikaati pole aktiveeritud ja/või sertifikaadi staatus on teadmata (OCSP: UNKNOWN).',
      en: 'Certificate is not activated or/and status of the certificate is unknown (OCSP said: UNKNOWN).'
    },
    304: {
      et: 'Sertifikaat on peatatud.',
      en: 'Certificates is suspended.'
    },
    305: {
      et: 'Sertifikaat on aegunud.',
      en: 'Certificate is expired.'
    },
    413: {
      et: 'Saabuva sõnumi maht ületab lubatud mahtu.',
      en: 'Incoming message exceeds permitted volume limit.'
    },
    503: {
      et: 'Päringute limiit on ületatud',
      en: 'The number of simultaneous requests of the service has been exceeded.'
    }
  };

  errorStatuses: any = ['SENDING_ERROR', 'USER_CANCEL', 'INTERNAL_ERROR', 'SIM_ERROR', 'PHONE_ABSENT', 'MID_NOT_READY'];

  statusErrorTranslations = {
    'SENDING_ERROR': {
      et: 'Audentimisvastuse saatmine nurjus',
      en: 'Sending authentication request to phone failed.'
    },
    'USER_CANCEL': {
      et: 'Kasutaja tühistas audentimise.',
      en: 'User canceled authentication.'
    },
    'INTERNAL_ERROR': {
      et: 'Teenuse tehniline viga.',
      en: 'Service techical error.'
    },
    'SIM_ERROR': {
      et: 'SIM kaardi viga.',
      en: 'SIM application error.'
    },
    'PHONE_ABSENT': {
      et: 'Audentimisvastuse saatmine nurjus',
      en: 'Sending authentication request to phone failed.'
    },
    'MID_NOT_READY': {
      et: 'Mobiili-ID funktsionaalsus pole valmis. Proovige mõne hetke pärast uuesti.',
      en: 'Mobile-ID functionality is not ready yet, try again after awhile.'
    }
  };

  hasError = false;
  errorCode: number;
  status: string;
  challengeId: number;
  errorMessage: string;
  language = 'et';
  authToken: string;

  constructor(json?: any, lang?: string) {
    if (lang) {
      this.language = lang;
    }
    this.status = json.status;
    this.challengeId = json.challengeId;
    this.errorCode = json.errorCode;
    if (this.errorCode !== 0) {
      this.hasError = true;

      if (this.errorCodes[this.errorCode][this.language]) {
        this.errorMessage = this.errorCodes[this.errorCode][this.language];
      } else {
        this.errorMessage = this.errorCodes[this.errorCode]['en'];
      }

      if (!this.errorMessage) {
        this.errorMessage = 'Unknown error';
      }
    }
  }

  checkStatusErrors() {
    if (this.errorStatuses.indexOf(this.status) > -1) {
      this.errorMessage = null;
      this.hasError = true;
      this.setStatusMessage();
      return true;
    } else {
      return false;
    }
  }

  setStatusMessage() {
    if (this.statusErrorTranslations[this.status][this.language]) {
      this.errorMessage = this.statusErrorTranslations[this.status][this.language];
    } else {
      this.errorMessage = this.statusErrorTranslations[this.status]['en'];
    }

    if (!this.errorMessage) {
      this.errorMessage = 'Unknown error';
    }
  }
}
