import {Injectable} from '@angular/core';
import {ConfigData} from './config-data';

@Injectable()
export class Config {
  public static getFacebookAppId(): string {
    return ConfigData.CONFIG_DATA.facebookAppId;
  }

  public static getGoogleClientId(): string {
    return ConfigData.CONFIG_DATA.googleClientId;
  }

  public static getGoogleReCaptchaKey() {
    return ConfigData.CONFIG_DATA.googleReCaptchaKey;
  }

  public getApiUrl(uriEnd: string): string {
    return ConfigData.CONFIG_DATA.backendUri + '/' + uriEnd;
  }

  public getIdLoginUrl(): string {
    return ConfigData.CONFIG_DATA.idCard.authUrl;
  }

  public getConfig() {
    return ConfigData.CONFIG_DATA;
  }

  public getMobileIdConfig() {
    return ConfigData.CONFIG_DATA.mobileId;
  }

  public getSmartIdConfig() {
    return ConfigData.CONFIG_DATA.smartId;
  }
}
