import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatInputErrorStateMatcher} from '../../validator/mat-input-state-validator';
import {AdvertService} from '../../service/advert/advert.service';
import {AdvertLite} from '../../class/advert-lite';
import {Advert} from '../../class/advert';
import {ToastService} from 'ng-uikit-pro-standard';
import {Router} from '@angular/router';
import {MockAdvertModalComponent} from '../../component/mock-advert-modal/mock-advert-modal.component';


@Component({
  selector: 'app-archive-menu',
  templateUrl: './archive-menu.component.html',
  styleUrls: ['./archive-menu.component.scss']
})
export class ArchiveMenuComponent implements OnInit {

  public static ORIGIN_CARD = 'ORIGIN_CARD';
  public static ORIGIN_DETAIL = 'ORIGIN_DETAIL';

  @Input() ad: AdvertLite;
  @Input() mockAdvertModal: MockAdvertModalComponent;
  @Input() menuOrigin: String; // where was the component included
  @Output() hideMenus = new EventEmitter();
  @Output() archiveEvent = new EventEmitter<Number>();

  archiveAdForm: FormGroup;
  submitInProgress = false;
  archiveAdFormSubmitted = false;
  inputErrorState: MatInputErrorStateMatcher;

  dealSuccessName = '';

  constructor(private formBuilder: FormBuilder,
              private advertService: AdvertService,
              private router: Router,
              private toastService: ToastService) {
  }

  get getArchiveAdForm() {
    return this.archiveAdForm.controls;
  }

  hideSubMenus() {
    this.archiveAdForm.reset();
    this.hideMenus.emit('hide');
  }

  ngOnInit() {
    this.dealSuccessName = 'dealSuccess' + this.ad.id.toString();
    this.initArchiveForm();
    this.inputErrorState = new MatInputErrorStateMatcher();
  }

  onChangeDealSuccess(value) {
    // successful deal:
    if (value === 'true') {
      this.getArchiveAdForm.price.setValidators(Validators.required);
    } else { // not successful deal:
      this.getArchiveAdForm.price.clearValidators();
    }
    this.getArchiveAdForm.price.updateValueAndValidity();
  }

  archiveAd() {
    if (this.ad.isMockAdvert()) {
      this.mockAdvertModal.show();
      return;
    }

    this.archiveAdFormSubmitted = true;
    if (this.submitInProgress || this.archiveAdForm.invalid) {
      return;
    }
    this.submitInProgress = true;

    const submitData = {
      'date': new Date(),
      'comment': this.getArchiveAdForm.comment.value,
      'price': this.getArchiveAdForm.price.value,
      'successful': this.getArchiveAdForm[this.dealSuccessName].value,
      'bookingBegin': this.getArchiveAdForm.bookingBegin.value,
      'bookingEnd': this.getArchiveAdForm.bookingEnd.value,
    };

    this.advertService.archiveAdvert(this.ad, submitData).subscribe(userDataResponse => {
        this.toastService.success('Kuulutus arhiveeritud!');
        this.ad = new Advert(userDataResponse);
        this.ad.booked = true;
      },
      error => {
        this.submitInProgress = false;

      }, () => {
        this.submitInProgress = false;
        this.hideSubMenus();

        if (this.menuOrigin === ArchiveMenuComponent.ORIGIN_DETAIL) {
            this.router.navigate(['']);
        } else {
          this.archiveEvent.emit(this.ad.id);
        }
      });
  }

  initArchiveForm() {
    const controlsConfig = {
      price: [''],
      comment: [''],
      bookingBegin: [this.ad.dateCreated, [Validators.required]],
      bookingEnd: ['', [Validators.required]],
    };
    controlsConfig[this.dealSuccessName] = ['', [Validators.required]];
    this.archiveAdForm = this.formBuilder.group(controlsConfig);

    this.getArchiveAdForm[this.dealSuccessName].valueChanges.subscribe(val => this.onChangeDealSuccess(val));
  }

  isDirtyAndInvalid(controlName: string): boolean {
    const control = this.getArchiveAdForm[controlName];
    return !!((control.touched && control.dirty || this.archiveAdFormSubmitted) && control.errors);
  }

  showDealSuccessError() {
    return this.archiveAdFormSubmitted && this.getArchiveAdForm[this.dealSuccessName].errors;
  }

}
