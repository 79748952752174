import {Injectable} from '@angular/core';
import {DataService} from '../data/data.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public static CHANGE_PASSWORD_URL = 'users/password';
  public static CHANGE_EMAIL_URL = 'users/email';
  public static CHANGE_PROFILE_URL = 'profiles/{id}';
  public static CHANGE_USER_URL = 'users/{id}/lite';
  public static CHANGE_ORGANIZATION_URL = 'organizations';
  public static MY_INFO_URL = 'me';
  public static ORGANIZATION_INFO_URL = 'organizations';
  public static GET_USER_PROFILE_URL = 'users/{id}/profile';
  public static CREATE_PROFILE_URL = 'profiles';
  public static PASSWORD_CHANGE_REQUEST_URL = 'users/change-password';
  public static CHANGE_NOTIFICATION_SETTINGS_URL = 'xxx'; // todo: replace with real endpoint
  public static RESET_PASSWORD_URL = 'users/change-password/{hash}';

  constructor(private dataService: DataService) {
  }

  changePassword(currentPassword: string, newPassword: string) {
    const data = {
      password: currentPassword,
      newPassword: newPassword
    };
    return this.dataService.put(UserService.CHANGE_PASSWORD_URL, data);
  }

  me() {
    return this.dataService.getOne(UserService.MY_INFO_URL);
  }

  getOrganization() {
    return this.dataService.getOne(UserService.ORGANIZATION_INFO_URL);
  }

  changeEmail(currentPassword: string, newEmail: string) {
    const data = {
      password: currentPassword,
      email: newEmail
    };
    return this.dataService.put(UserService.CHANGE_EMAIL_URL, data);
  }

  changeUserProfile(profileId, profileData: Object) {
    const url = UserService.CHANGE_PROFILE_URL.replace('{id}', profileId);
    return this.dataService.put(url, profileData);
  }

  changeUser(userId, userData: Object) {
    const url = UserService.CHANGE_USER_URL.replace('{id}', userId);
    return this.dataService.put(url, userData);
  }

  changeOrganizationProfile(profileData: Object) {
    return this.dataService.put(UserService.CHANGE_ORGANIZATION_URL, profileData);
  }

  getUserProfile(id) {
    const url = UserService.GET_USER_PROFILE_URL.replace('{id}', id);
    return this.dataService.getOne(url);
  }

  createProfile(data) {
    return this.dataService.post(UserService.CREATE_PROFILE_URL, data);
  }

  requestPasswordLink(email) {
    return this.dataService.login(UserService.PASSWORD_CHANGE_REQUEST_URL, {email: email});
  }

  changeNotificationSetting(settingName, value) {
    return this.dataService.post(UserService.CHANGE_NOTIFICATION_SETTINGS_URL, {settingName: value});
  }

  resetPassword(password, hash) {
    const url = UserService.RESET_PASSWORD_URL.replace('{hash}', hash);
    return this.dataService.login(url, {password: password});
  }

  confirmEmailChange(hash: string): Observable<void> {
    return this.dataService.post(UserService.CHANGE_EMAIL_URL + '/' + hash, {});
  }
}
