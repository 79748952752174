import {Injectable} from '@angular/core';
import {DataService} from '../data/data.service';
import {map} from 'rxjs/operators';
import {DomainValue} from '../../class/domain-value';
import {isArray} from 'util';

@Injectable({
  providedIn: 'root'
})
export class DomainValueService {

  public static GET_DOMAIN_VALUES_URL = 'domain-values';

  public static DOMAIN_VALUES_BY_CODE = 'DOMAIN_VALUES_BY_CODE';
  public static DOMAIN_VALUES_BY_DOMAIN = 'DOMAIN_VALUES_BY_DOMAIN';
  public static DOMAIN_VALUES_BY_GROUP = 'DOMAIN_VALUES_BY_GROUP';

  // this is used so that JSON.parse wouldn't have to be called constantly
  public domainValuesCached = {};

  constructor(private dataService: DataService) {
  }

  setDomainValuesToLocalStorage() {
    return this.dataService.getList(DomainValueService.GET_DOMAIN_VALUES_URL).pipe(
      map((response: any) => {
        if (response) {
          const domainValuesByCode = {};
          const domainValuesByDomain = {};
          const domainValuesByGroup = {};
          Object.keys(response).forEach((key) => {
            const domainValueData = response[key];

            // initializes fields if necessary
            if (typeof domainValuesByDomain[domainValueData.domain] === 'undefined') {
              domainValuesByDomain[domainValueData.domain] = [];
            }
            if (domainValueData.group && typeof domainValuesByGroup[domainValueData.group.code] === 'undefined') {
              domainValuesByGroup[domainValueData.group.code] = [];
            }

            // sets value to correct keys
            domainValuesByCode[domainValueData.code] = domainValueData;
            if (domainValueData.domain) {
              domainValuesByDomain[domainValueData.domain].push(domainValueData);
            }
            if (domainValueData.group && domainValueData.group.code) {
              domainValuesByGroup[domainValueData.group.code].push(domainValueData);
            }
          });

          localStorage.setItem(DomainValueService.DOMAIN_VALUES_BY_CODE, JSON.stringify(domainValuesByCode));
          localStorage.setItem(DomainValueService.DOMAIN_VALUES_BY_DOMAIN, JSON.stringify(domainValuesByDomain));
          localStorage.setItem(DomainValueService.DOMAIN_VALUES_BY_GROUP, JSON.stringify(domainValuesByGroup));
        }
      })
    );
  }

  getDomainValueByCode(code: string): DomainValue {
    if (typeof this.domainValuesCached[DomainValueService.DOMAIN_VALUES_BY_CODE] === 'undefined') {
      this.domainValuesCached[DomainValueService.DOMAIN_VALUES_BY_CODE] =
        JSON.parse(localStorage.getItem(DomainValueService.DOMAIN_VALUES_BY_CODE));
    }

    return new DomainValue(this.domainValuesCached[DomainValueService.DOMAIN_VALUES_BY_CODE][code]);
  }

  getDomainValuesByDomain(domain: string): DomainValue[] {
    return this.getDomainValuesByGrouping(DomainValueService.DOMAIN_VALUES_BY_DOMAIN, domain);
  }

  // merges KASI types under KOM
  mergeKomKasiObjectTypes(selectElementValues = []) {
    const labelsAdded = [];
    const result = [];
    selectElementValues.forEach((element, index) => {
      if (element.groupCode === 'OBJECT_TYPE_KASI' || element.groupCode === 'OBJECT_TYPE_KOM') {
        element.group = 'Mitteeluruumid';
        // skip elements whose label was already added
        if (labelsAdded.includes(element.label)) {
          return;
        } else {
          labelsAdded.push(element.label);
        }
      }
      result.push(element);
    });
    return result;
  }

  getDomainValuesAsSelectOptions(domainCode: string, addGroupAndSort?): { [key: string]: string }[] {
    const codes = this.getDomainValuesByDomain(domainCode);
    let result = [];
    codes.forEach((domainValue) => {
      const element = {
        value: domainValue.code,
        label: domainValue.translations.et
      };
      if (addGroupAndSort) {
        if (domainValue.group) {
          element['group'] = domainValue.group.translations.et;
          element['sortOrderGroup'] = domainValue.group.sortOrder;
          element['sortOrderItem'] = domainValue.sortOrder;
          element['groupCode'] = domainValue.group.code;
        } else {
          element['group'] = '-';
          element['sortOrderGroup'] = '999';
          element['sortOrderItem'] = '999';
          element['groupCode'] = '-';
        }
      }
      result.push(element);
    });

    if (addGroupAndSort) {
      result = this.sortGroupedSelectElements(result);
    }

    // sorts array so that 'Puudub' would be the first element
    result.sort((a, b) => {
      if (a.label.toLowerCase() === 'puudub') {
        return -1;
      } else if (b.label.toLowerCase() === 'puudub') {
        return 1;
      } else {
        return 0;
      }
    });
    return result;
  }

  getObjectValuesAsSelectOptions(objectValues: { [key: string]: { [key: string]: string } }) {
    const result = [];
    Object.entries(objectValues).forEach(([key, value]) => {
      result.push({
        value: key,
        label: value.et
      });
    });


    return result;
  }

  getDomainValueTranslationByCodes(codes: string): string {
    if (!codes || isArray(codes)) { // @todo: remove isArray later
      return '';
    }

    const codesArray = codes.split(',');
    codesArray.forEach((code, index) => {
      if (this.getDomainValueByCode(code).translations) {
        codesArray[index] = this.getDomainValueByCode(code).translations.et;
      } else {
        codesArray[index] = code;
      }
    });
    return codesArray.join(', ');
  }

  getObjectValueTranslationByCodes(codes: string, objectValues: {}): string {
    if (!codes) {
      return '';
    }

    const codesArray = codes.split(',');
    codesArray.forEach((code, index) => {
      if (objectValues[code]) {
        codesArray[index] = objectValues[code].et;
      } else {
        codesArray[index] = code;
      }
    });
    return codesArray.join(', ');
  }

  private getDomainValuesByGrouping(grouping: string, subGroupingKey: string): DomainValue[] {
    const result = [];
    if (typeof this.domainValuesCached[grouping] === 'undefined') {
      this.domainValuesCached[grouping] = {};
    }
    if (typeof this.domainValuesCached[grouping][subGroupingKey] === 'undefined') {
      this.domainValuesCached[grouping][subGroupingKey] = JSON.parse(localStorage.getItem(grouping))[subGroupingKey];
    }

    const resultAsStringArray = this.domainValuesCached[grouping][subGroupingKey];
    Object.keys(resultAsStringArray).forEach((key) => {
      result.push(new DomainValue(resultAsStringArray[key]));
    });
    return result;
  }

  getDomainValuesByGroup(group): DomainValue[] {
    return this.getDomainValuesByGrouping(DomainValueService.DOMAIN_VALUES_BY_GROUP, group);
  }

  sortGroupedSelectElements(x) {
    x.sort((a, b) => {
      return a.sortOrderItem - b.sortOrderItem;
    });
    x.sort((a, b) => {
      return a.sortOrderGroup - b.sortOrderGroup;
    });
    return x;
  }

  getObjectTypeGroups() {
    return [
      DomainValue.OBJECT_TYPE_KASI,
      DomainValue.OBJECT_TYPE_KOM,
      DomainValue.OBJECT_TYPE_LIVING,
      DomainValue.OBJECT_TYPE_LAND
    ];
  }
}

