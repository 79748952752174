import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Advert} from '../../class/advert';
import {AdvertService} from '../../service/advert/advert.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {DomainValueService} from '../../service/domain-value/domain-value.service';
import {DomainValue} from '../../class/domain-value';
import {isArray} from 'util';
import {MatChipList, MatDatepicker} from '@angular/material';
import {FormValidators} from '../../validator/form-validators';
import {ObjectValues} from '../../class/object-values';
import {MDBModalRef, ToastService} from 'ng-uikit-pro-standard';
import {DataService} from '../../service/data/data.service';
import {Gallery, GalleryItem, ImageItem} from '@ngx-gallery/core';
import {Lightbox} from '@ngx-gallery/lightbox';
import {ImageService} from '../../service/image/image.service';
import {FileUploader} from 'ng2-file-upload';
import {AuthService} from '../../service/auth/auth.service';
import {Image} from '../../class/image';
import {Config} from '../../config/config';
import {UserProfileComponent} from '../../user/user-profile/user-profile.component';
import {Error} from '../../class/error';
import {MockAdvert} from '../../class/mock-advert';
import {MockAdvertModalComponent} from '../../component/mock-advert-modal/mock-advert-modal.component';

@Component({
  selector: 'app-advert-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class AdvertEditComponent implements OnInit {

  @ViewChild('matChipList') matChipList: MatChipList;
  @ViewChild('editAddressModal') editAddressModal: MDBModalRef;
  @ViewChild('picker') bookedUntilPicker: MatDatepicker<null>;
  @ViewChild('mockAdvertModal') mockAdvertModal: MockAdvertModalComponent;

  ad: Advert;
  optionsSelect: Array<any>;
  editAdvertForm: FormGroup;
  editAdvertFormSubmitted = false;
  editAdvertFormOptions = [];
  submitAdInProgress = false;
  selectedLanguage = 'et';
  additionalInfoValues: DomainValue[];
  editAdvertFormErrors = {};

  knrUrl = '';
  ehrUrl = '';

  // images related
  sortablejsOptions = {
    animation: 150,
    onUpdate: (event: any) => {
      this.afterImageSorted(event);
    }
  };
  galleryImageItems: GalleryItem[];
  images;
  public uploader: FileUploader;
  public hasBaseDropZoneOver = false;
  imageUploadInProgressCount = [];

  editAddressForm: FormGroup;
  submitAddressInProgress = false;
  selectedAddress = {};
  inAadress = null;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private advertService: AdvertService,
              private formBuilder: FormBuilder,
              private domainValueService: DomainValueService,
              private dataService: DataService,
              public gallery: Gallery,
              private lightbox: Lightbox,
              private imageService: ImageService,
              private config: Config,
              private toastService: ToastService) {
  }

  get getAdvertForm() {
    return this.editAdvertForm.controls;
  }

  get getAddressForm() {
    return this.editAddressForm.controls;
  }

  ngOnInit() {
    this.initImageUpload();
    this.optionsSelect = [
      {value: 'et', label: 'Eesti keel'},
      {value: 'en', label: 'Inglise keel'},
      {value: 'ru', label: 'Vene keel'},
    ];
    const advertId = +this.route.snapshot.paramMap.get('id');
    if (advertId === 0) {
      this.ad = new MockAdvert();
      this.knrUrl = this.advertService.getKnrUrl(this.ad);
      this.ehrUrl = this.advertService.getEhrUrl(this.ad);
      this.initInAddress();
      this.initEditAdvertForm();
      this.initEditAddressForm();
      this.initModalGallery();
    } else {
      this.advertService.getAdvert(advertId).subscribe(ad => {
        this.ad = ad;
        this.initEditAdvertForm();
        this.knrUrl = this.advertService.getKnrUrl(this.ad);
        this.ehrUrl = this.advertService.getEhrUrl(this.ad);
        this.initInAddress();
        this.initEditAddressForm();
        this.initModalGallery();
      });
    }
    this.additionalInfoValues = this.domainValueService
      .getDomainValuesByDomain(DomainValue.CUSTOM_FIELD_ADVERT_ADDITIONAL_INFO);
  }

  initEditAdvertForm() {
    this.editAdvertForm = this.formBuilder.group({
      type: [this.ad.type, []],
      ownership: [this.ad.ownership, []],
      cadasterNumber: [this.ad.cadasterNumber, []],
      propertyArea: [this.ad.propertyArea, []],
      rooms: [this.ad.rooms, []],
      buildYear: [this.ad.buildYear, []],
      supportingStructure: [this.ad.getAsArray('supportingStructure'), []],
      sanitaryFurnitureState: [this.ad.getAsArray('sanitaryFurnitureState'), []],
      heatingSystem: [this.ad.getAsArray('heatingSystem'), []],
      coldWater: [this.ad.getAsArray('coldWater'), []],
      ventilation: [this.ad.getAsArray('ventilation'), []],
      security: [this.ad.getAsArray('security'), []],
      stove: [this.ad.getAsArray('stove'), []],
      parking: [this.ad.getAsArray('parking'), []],
      objectType: [this.ad.objectType, []],
      registerNumber: [this.ad.registerNumber, []],
      intendedUsages: [this.ad.getAsArray('intendedUsages'), []],
      closedNetArea: [this.ad.closedNetArea, []],
      bedroomCount: [this.ad.bedroomCount, []],
      energyLabel: [this.ad.energyLabel, []],
      roof: [this.ad.getAsArray('roof'), []],
      sanitary: [this.ad.getAsArray('sanitary'), []],
      sewerage: [this.ad.sewerage, []],
      gasSupply: [this.ad.gasSupply, []],
      communications: [this.ad.getAsArray('communications'), []],
      alarm: [this.ad.getAsArray('alarm'), []],
      additionalInfo: [this.ad.additionalInfo, []],
      salesSentenceET: [this.ad.salesSentence.et, []],
      salesSentenceEN: [this.ad.salesSentence.en, []],
      salesSentenceRU: [this.ad.salesSentence.ru, []],
      descriptionET: [this.ad.description.et, []],
      descriptionEN: [this.ad.description.en, []],
      descriptionRU: [this.ad.description.ru, []],
      price: [this.ad.price, []],
      showStreetNumber: [this.ad.showStreetNumber, []],
      showApartmentNumber: [this.ad.showApartmentNumber, []],
      booked: [this.ad.booked, []],
      cadasterImageAsLast: [this.ad.cadasterImageAsLast, []],
      bookedUntil: [this.ad.booked ? this.ad.bookedUntil : new Date(), []],
      floors: [this.ad.floors, []],
      floor: [this.ad.floor, []],
    }, {validator: FormValidators.validateAddAdvertisement.bind(this)});

    this.populateOptions();
  }

  populateOptions() {
    const objectTypeValues = this.domainValueService.getDomainValuesAsSelectOptions(DomainValue.OBJECT_TYPE, true);
    this.editAdvertFormOptions['objectType'] = this.domainValueService.mergeKomKasiObjectTypes(objectTypeValues);
    this.editAdvertFormOptions['ownership'] =
      this.domainValueService.getDomainValuesAsSelectOptions(DomainValue.OBJECT_OWNERSHIP);
    this.editAdvertFormOptions['supportingStructure'] =
      this.domainValueService.getDomainValuesAsSelectOptions(DomainValue.CUSTOM_FIELD_OBJECT_SUPPORTING_STRUCTURE);
    this.editAdvertFormOptions['sanitaryFurnitureState'] =
      this.domainValueService.getDomainValuesAsSelectOptions(DomainValue.CUSTOM_FIELD_OBJECT_SANITARY_FURNITURE_STATE);
    this.editAdvertFormOptions['heatingSystem'] =
      this.domainValueService.getDomainValuesAsSelectOptions(DomainValue.CUSTOM_FIELD_OBJECT_HEATING_SYSTEM);
    this.editAdvertFormOptions['coldWater'] =
      this.domainValueService.getDomainValuesAsSelectOptions(DomainValue.CUSTOM_FIELD_OBJECT_COLD_WATER);
    this.editAdvertFormOptions['ventilation'] =
      this.domainValueService.getDomainValuesAsSelectOptions(DomainValue.CUSTOM_FIELD_OBJECT_VENTILATION);
    this.editAdvertFormOptions['security'] =
      this.domainValueService.getDomainValuesAsSelectOptions(DomainValue.CUSTOM_FIELD_OBJECT_SECURITY);
    this.editAdvertFormOptions['stove'] =
      this.domainValueService.getDomainValuesAsSelectOptions(DomainValue.CUSTOM_FIELD_OBJECT_STOVE);
    this.editAdvertFormOptions['parking'] =
      this.domainValueService.getDomainValuesAsSelectOptions(DomainValue.CUSTOM_FIELD_OBJECT_PARKING);
    this.editAdvertFormOptions['energyLabel'] =
      this.domainValueService.getDomainValuesAsSelectOptions(DomainValue.CUSTOM_FIELD_OBJECT_ENERGY_LABEL);
    this.editAdvertFormOptions['roof'] =
      this.domainValueService.getDomainValuesAsSelectOptions(DomainValue.CUSTOM_FIELD_OBJECT_ROOF);
    this.editAdvertFormOptions['sanitary'] =
      this.domainValueService.getDomainValuesAsSelectOptions(DomainValue.CUSTOM_FIELD_OBJECT_SANITARY);
    this.editAdvertFormOptions['sewerage'] =
      this.domainValueService.getDomainValuesAsSelectOptions(DomainValue.CUSTOM_FIELD_OBJECT_SEWERAGE);
    this.editAdvertFormOptions['gasSupply'] =
      this.domainValueService.getDomainValuesAsSelectOptions(DomainValue.CUSTOM_FIELD_OBJECT_GAS_SUPPLY);
    this.editAdvertFormOptions['communications'] =
      this.domainValueService.getDomainValuesAsSelectOptions(DomainValue.CUSTOM_FIELD_OBJECT_COMMUNICATIONS);
    this.editAdvertFormOptions['alarm'] =
      this.domainValueService.getDomainValuesAsSelectOptions(DomainValue.CUSTOM_FIELD_OBJECT_ALARM);

    this.editAdvertFormOptions['intendedUsages'] = this.domainValueService
      .getObjectValuesAsSelectOptions(ObjectValues.OBJECT_INTENDED_USAGES);
    this.editAdvertFormOptions['type'] = this.domainValueService.getObjectValuesAsSelectOptions(ObjectValues.OBJECT_TYPE);
  }

  onSubmit() {
    if (this.ad.isMockAdvert()) {
      this.mockAdvertModal.show();
      return;
    }

    // bookedUntil is required only when 'booked' = true
    if (this.getAdvertForm['booked'].value === false) {
      this.editAdvertForm.removeControl('bookedUntil');
    }

    const that = this;
    const submitData = {
      'salesSentence': {},
      'description': {}
    };

    this.editAdvertFormSubmitted = true;

    if (this.submitAdInProgress || this.editAdvertForm.invalid) {
      return;
    }
    this.submitAdInProgress = true;
    this.editAdvertFormErrors = {};

    Object.keys(this.getAdvertForm).forEach(function (controlName) {
      if (controlName.indexOf('salesSentence') >= 0) {
        const language = controlName.replace('salesSentence', '');
        submitData['salesSentence'][language.toLowerCase()] = that.getAdvertForm[controlName].value;

      } else if (controlName.indexOf('description') >= 0) {
        const language = controlName.replace('description', '');
        submitData['description'][language.toLowerCase()] = that.getAdvertForm[controlName].value;

      } else {
        submitData[controlName] = that.getAdvertForm[controlName].value;
        if (isArray(submitData[controlName])) {
          submitData[controlName] = submitData[controlName].join(',');
        }
      }
    });

    submitData['address'] = {
      'country': 'est',
      'externalId': this.ad.address.externalId,
      'sublocality': this.ad.address.subLocality
    };

    this.advertService.updateAdvert(this.ad, submitData).subscribe(response => {
      this.advertService.patchAdvert(this.ad, {
        price: this.getAdvertForm['price'].value,
        cadasterImageAsLast: this.getAdvertForm['cadasterImageAsLast'].value
      }).subscribe(_ => {
        this.toastService.success('Kuulutus muudetud!');
        that.router.navigate(['/advert/details/', this.ad.id]);
      }, error => {
        this.toastService.error('Kuulutuse salvestamisel juhtus viga!');
        this.editAdvertFormErrors = error.errors;
      });
    }, error => {
      this.editAdvertFormErrors = error.errors;
      this.toastService.error('Kuulutuse salvestamisel juhtus viga!');
      this.submitAdInProgress = false;

    }, () => {
      this.submitAdInProgress = false;
    });

    // add (previously removed) validator because user might want to save again without leaving the page
    this.editAdvertForm.addControl('bookedUntil',
      new FormControl((this.ad.booked ? this.ad.bookedUntil : new Date()), Validators.required));
  }

  addRemoveAdditionalInfo(additionalInfoCode) {
    if (this.ad.additionalInfo.includes(additionalInfoCode)) {
      delete this.ad.additionalInfo[this.ad.additionalInfo.indexOf(additionalInfoCode)];
    } else {
      this.ad.additionalInfo.push(additionalInfoCode);
    }
  }

  toggleVisibility(event, fieldName) {
    const isVisible = !event.target.parentElement.classList.contains('active');
    this.ad.visibility[fieldName] = isVisible;
    this.advertService.changeFieldVisibility(this.ad, fieldName, isVisible).subscribe();
  }

  isInvalid(formControlName) {
    return this.editAdvertFormErrors && this.editAdvertFormErrors[formControlName];
  }

  showError(formControlName) {
    let errorText = '';
    if (this.editAdvertFormErrors && this.editAdvertFormErrors[formControlName]) {
      if (this.editAdvertFormErrors[formControlName] === Error.errorDuplicate) {
        if (formControlName === 'registerNumber' || formControlName === 'cadasterNumber') {
          errorText = 'See väärtus on juba kasutusel!';
        }
      }
    }
    return errorText;
  }

  initInAddress() {
    const that = this;
    document.addEventListener('inaadressLoaded.inAdsEdit', this.inAadressLoaded);

    // listens to addressLite selection in autocomplete field
    document.addEventListener('addressSelected.inAdsEdit', function (e) {
      // @ts-ignore
      that.inAadress.hideResult();
      // @ts-ignore
      that.inAadress.setAddress(e.detail.paadress);

      // @ts-ignore
      that.selectedAddress = e.detail;

      // shows correct info in address modal
      that.advertService.populateAddressFromInAds(that.ad, e['detail']);

      // @ts-ignore
      const adrId = e.detail.adr_id;

      // @ts-ignore
      const apartmentChosen = 'hoone_ads_oid' in e.detail;

      // if this equals true, then apartment has been just chosen
      if (apartmentChosen) {
        that.hideApartmentText();
      }

      const url = that.config.getConfig().inAdsUrl + adrId;

      that.dataService.getJSONPList(url).subscribe(resultBuildings => {
        if ('addresses' in resultBuildings) {
          let cadasterNumber = '';

          // @ts-ignore
          resultBuildings.addresses.forEach(addressData => {
            if (!cadasterNumber && addressData.liikVal === 'KATASTRIYKSUS') {
              cadasterNumber = addressData.tunnus;
            }
          });

          if (!apartmentChosen) {
            that.getAdvertForm['cadasterNumber'].setValue(cadasterNumber);
          }
        }

      });
    });

    // runs after interval because element might not have been initalized
    const intervalId = setInterval(function () {
      if (document.getElementById('InAadressDivEditAd')) {
        clearInterval(intervalId);
        // @ts-ignore
        that.inAadress = new InAadress({
          'container': 'InAadressDivEditAd',
          mode: 3,
          results: 20,
          appartment: 1,
          unik: 0,
          nocss: true,
          searchLayers: ['EHITISHOONE', 'KATASTRIYKSUS'],
          namespace: 'inAdsEdit'
        });
        that.inAadress.setAddress(that.ad.address.address);
      }
    }, 100);
  }

  inAadressLoaded() {
    const rootDom = document.querySelector('#InAadressDivEditAd');
    rootDom.querySelector('input').className += ' form-control';
    rootDom.querySelector('a[title="puhasta"]').remove();
  }

  hideApartmentText() {
    const rootDom = document.querySelector('#InAadressDivEditAd');
    rootDom.querySelector('span.appartments').innerHTML = 'Korter';
  }

  saveAddress() {
    if (this.ad.isMockAdvert()) {
      this.mockAdvertModal.show();
      return;
    }

    if (this.submitAddressInProgress) {
      return;
    }
    this.submitAddressInProgress = true;

    const submitData = this.advertService.createUpdateAdvertDataObject(this.ad);
    submitData.address.sublocality = this.getAddressForm['subLocality'].value;
    this.advertService.updateAdvert(this.ad, submitData).subscribe(adResponse => {
      this.ad = new Advert(adResponse);
      this.inAadress.setAddress(this.ad.address.address);
      this.toastService.success('Aadress salvetatud!');
      this.submitAddressInProgress = false;
      this.editAddressModal.hide();
    }, errorResponse => {
      this.toastService.error('Aadressi salvestamine ebaõnnestus!');
    });
  }

  initEditAddressForm() {
    this.editAddressForm = this.formBuilder.group({
      subLocality: [this.ad.address.subLocality, []],
    });
  }

  showCadasterRegNumberMissingError() {
    return this.editAdvertForm.errors && this.editAdvertForm.errors.cadasterOrRegisterNumberRequired && this.editAdvertFormSubmitted;
  }

  initModalGallery() {
    // imagesToGalleryItems
    this.galleryImageItems = this.ad.images.map(image =>
      new ImageItem({
        src: image.large,
        thumb: image.small,
        medium: image.medium,
        sortOrder: image.sortOrder,
        advertImageId: image.advertImageId
      })
    );

    this.gallery.ref().load(this.galleryImageItems);
  }

  initImageUpload() {
    this.uploader = new FileUploader({
      url: this.config.getApiUrl(UserProfileComponent.UPLOAD_IMAGE_URL),
      headers: [{name: AuthService.X_AUTH_TOKEN, value: AuthService.getAuthToken()}]
    });

    // file is added to queue, called for every file (current implementation means that upload starts immediately)
    this.uploader.onAfterAddingFile = (item: any) => {
      if (this.ad.isMockAdvert()) {
        this.mockAdvertModal.show();
        return;
      }

      this.imageUploadInProgressCount.push('x');
      item.upload();
    };

    // file has been uploaded (called after each file has been uploaded)
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      const newImage = new Image(JSON.parse(response));
      const imageData = this.createImageSubmitData(newImage);

      // start polling for picture
      this.imageService.addImageToAdvert(this.ad.id, imageData).subscribe(imageDataResponse => {
        const image = new Image(imageDataResponse);
        setTimeout(this.pollForImage(image.imageId), 100);
      });
    };
  }

  pollForImage(imageId) {
    return () => {
      let imageReady = false;
      this.imageService.getAllImagesForAdvert(this.ad.id).subscribe(imagesData => {
        imagesData.forEach((imageData, index) => {
          const image = new Image(imageData);
          if (Number(imageId) === image.imageId) {
            // found correct image
            if (image.small) {
              // image has been uploaded and is available
              imageReady = true;
              this.toastService.success('Pilt lisatud!');
              this.ad.images.push(image);
              this.imageUploadInProgressCount.pop();
            }
          }
        });
        if (!imageReady) {
          setTimeout(this.pollForImage(imageId), 200);
        } else {
          this.updateImageSortOrderAndGallery();
        }
      }, err => {
        this.toastService.error('Midagi läks pildi lisamisel valesti!');
        this.imageUploadInProgressCount.pop();
      });
    };

  }

  createImageSubmitData(image: Image) {
    return {
      'image': image.advertImageId,
      'sortOrder': this.getMaxSortOrder()
    };
  }

  public getMaxSortOrder() {
    return 9999;
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  public onFileDrop(e: any): void {
  }

  public deleteImage(advertImageId) {
    if (this.ad.isMockAdvert()) {
      this.mockAdvertModal.show();
      return;
    }

    if (confirm('Kas oled kindel, et soovid pildi kustutada?')) {
      this.imageService.deleteImageFromAdvert(this.ad.id, advertImageId).subscribe(_ => {
        this.ad.images = this.ad.images.filter(image => {
          return image.advertImageId !== Number(advertImageId);
        });
        this.updateImageSortOrderAndGallery();
      }, error => {
        this.toastService.error('Pildi kustutamine ei õnnestunud!');
      });
    }
  }

  sortGalleryItems() {
    this.galleryImageItems = this.ad.images.map(image =>
      new ImageItem({
        src: image.large,
        thumb: image.small,
        medium: image.medium,
        sortOrder: image.sortOrder,
        advertImageId: image.advertImageId
      })
    );
    this.gallery.ref().load(this.galleryImageItems);
  }

  afterImageSorted(e) {
    const advertImageId = e.item.dataset.advertimageid;
    const newIndex = e.newIndex;
    const imageMovedForward = (e.oldIndex < e.newIndex);

    // save sort on serverside
    if (this.ad.id !== 0) {
      this.imageService.editImageSortOrder(this.ad.id, advertImageId, {sortOrder: newIndex}).subscribe();
    }

    // sort local array of images by sortOrder
    // if an image is moved between images 0 and 1 from position 2:
    // then the order is saved as 0; 0.5; 1
    // afterwards the array is traversed once again and now indexes are used as sortOrder:
    // 0; 1; 2
    this.ad.images.forEach((image, index) => {
      if (image.advertImageId === Number(advertImageId)) {
        const modifiedNewIndex = (imageMovedForward ? newIndex + 0.5 : newIndex - 0.5);
        this.ad.images[index].sortOrder = modifiedNewIndex;
      }
    });

    this.ad.images.sort((a, b) => (a.sortOrder > b.sortOrder) ? 1 : -1);
    this.updateImageSortOrderAndGallery();

  }

  updateImageSortOrderAndGallery() {
    this.ad.images = this.useArrayIndexesAsSortOrder();
    this.sortGalleryItems();
  }

  useArrayIndexesAsSortOrder() {
    const newImageArray: Image[] = [];
    this.ad.images.forEach((image, index) => {
      image.sortOrder = index;
      newImageArray.push(image);
    });

    return newImageArray;
  }
}
