import {Image} from './image';

export class Organization {
  id: number;
  name = '';
  registryCode = '';
  address = '';
  url = '';
  phone = '';
  email = '';
  image: Image;

  constructor(json?: any) {
    if (!json) {
      return;
    }

    this.id = json.id;
    this.name = json.name;
    this.address = json.address;
    this.registryCode = json.registryNumber;
    this.url = json.url;
    this.phone = json.phone;
    this.email = json.email;
    if (json.image) {
      this.image = new Image(json.image);
    }
  }
}
