import {Advert} from './advert';

export class MockAdvert extends Advert {
  constructor() {

    const mockData = {
      'id': 0,
      'status': 'active',
      'project': 0,
      'user': 0,
      'avatar': {
        'id': 13907,
        'name': 'file',
        'imageId': 203931,
        'thumbnail': 'assets/img/mockadvert/S6DvNOXmR2kRMnke0czq.jpg',
        'small': 'assets/img/mockadvert/9yL3NmNB4KdtUPwrqMyo.jpg',
        'medium': 'assets/img/mockadvert/hNTxX1i16GngdeL56u00.jpg',
        'large': 'assets/img/mockadvert/4XTaaBbUOvtEHoVVvaWG.jpg',
        'dateCreated': '2019-03-28T11:55:26.305Z',
        'sortOrder': 0
      },
      'address': {
        'address': 'Väike-Rae tee 25/2-18, Rae küla, Rae vald, Harju maakond',
        'room': '18',
        'house': '25/2',
        'street': 'Väike-Rae tee',
        'sublocality': null,
        'locality': 'Rae küla',
        'city': 'Rae vald',
        'county': 'Harju maakond',
        'country': 'est',
        'externalId': 'ER03685464',
        'lat': 59.376592,
        'lng': 24.858961,
        'ehak': '6713',
        'ehakmk': '37',
        'ehakov': '653'
      },
      'price': 61900.00,
      'pricePerM2': 2134.00,
      'vat': 'notIncludedNorAdded',
      'includesVat': false,
      'booked': false,
      'bookedUntil': null,
      'type': 'sale',
      'objectType': 'OBJECT_TYPE_APARTMENT',
      'rooms': 1,
      'floor': 3,
      'floors': 3,
      'totalArea': null,
      'closedNetArea': 29.00,
      'propertyArea': 6655.00,
      'cadasterNumber': '65301:001:3809',
      'registerNumber': '111111',
      'images': [{
        'id': 13907,
        'name': 'file',
        'imageId': 203931,
        'thumbnail': 'assets/img/mockadvert/S6DvNOXmR2kRMnke0czq.jpg',
        'small': 'assets/img/mockadvert/9yL3NmNB4KdtUPwrqMyo.jpg',
        'medium': 'assets/img/mockadvert/hNTxX1i16GngdeL56u00.jpg',
        'large': 'assets/img/mockadvert/4XTaaBbUOvtEHoVVvaWG.jpg',
        'dateCreated': '2019-03-28T11:55:26.305Z',
        'sortOrder': 0
      }, {
        'id': 13901,
        'name': 'file',
        'imageId': 203925,
        'thumbnail': 'assets/img/mockadvert/yA9fBxWyHcW0zeBFh1lY.jpg',
        'small': 'assets/img/mockadvert/iESPoBWMOQPykjJHKEwC.jpg',
        'medium': 'assets/img/mockadvert/B30cxt0kaitRINUf6RvW.jpg',
        'large': 'assets/img/mockadvert/DrnYPm1ljLtH4mAYE9WZ.jpg',
        'dateCreated': '2019-03-28T11:55:14.927Z',
        'sortOrder': 1
      }, {
        'id': 13904,
        'name': 'file',
        'imageId': 203928,
        'thumbnail': 'assets/img/mockadvert/5PontUKGBwY2psxxkUFC.jpg',
        'small': 'assets/img/mockadvert/Fl7ocTrKuuVP7TEQcDJ3.jpg',
        'medium': 'assets/img/mockadvert/OS9vrMQ4UxrcnOwE8Wzv.jpg',
        'large': 'assets/img/mockadvert/L7tYaYtjRDg4PHEFdYlJ.jpg',
        'dateCreated': '2019-03-28T11:55:20.596Z',
        'sortOrder': 2
      }, {
        'id': 13909,
        'name': 'file',
        'imageId': 203933,
        'thumbnail': 'assets/img/mockadvert/XSNEGjOAI3Tr1x0V9Lkg.jpg',
        'small': 'assets/img/mockadvert/x19ycnjXO9IUPpa5Fxlc.jpg',
        'medium': 'assets/img/mockadvert/yF2BG6RSvc5VPDyAEyti.jpg',
        'large': 'assets/img/mockadvert/0pcRC31GW1dSs8Xd8yXD.jpg',
        'dateCreated': '2019-03-28T11:56:11.982Z',
        'sortOrder': 3
      }, {
        'id': 13906,
        'name': 'file',
        'imageId': 203930,
        'thumbnail': 'assets/img/mockadvert/uu693YlTrBUhCt6suHyh.jpg',
        'small': 'assets/img/mockadvert/zy8iI77elrdpA7a6gJO5.jpg',
        'medium': 'assets/img/mockadvert/LPP7VYqL9OdM6n6dPavS.jpg',
        'large': 'assets/img/mockadvert/GTyCysoEZnG3eHrGt9r9.jpg',
        'dateCreated': '2019-03-28T11:55:25.255Z',
        'sortOrder': 4
      }, {
        'id': 13902,
        'name': 'file',
        'imageId': 203926,
        'thumbnail': 'assets/img/mockadvert/5zuwuVtBl96PgMQLd4k2.jpg',
        'small': 'assets/img/mockadvert/dH5G9SBRHl4gA3bLbJEX.jpg',
        'medium': 'assets/img/mockadvert/uh7tc3vgnd8EL67aoQ7Y.jpg',
        'large': 'assets/img/mockadvert/VimA0lBBt8c65nCxr3Jh.jpg',
        'dateCreated': '2019-03-28T11:55:16.486Z',
        'sortOrder': 5
      }, {
        'id': 13908,
        'name': 'file',
        'imageId': 203932,
        'thumbnail': 'assets/img/mockadvert/WvS65Buhlv68n6r43RTU.jpg',
        'small': 'assets/img/mockadvert/Qzfiku1UjSJkocOxDYOo.jpg',
        'medium': 'assets/img/mockadvert/HeImSRIr3uUlspJDqmfS.jpg',
        'large': 'assets/img/mockadvert/prjlWEdJMKCATHUOFFQk.jpg',
        'dateCreated': '2019-03-28T11:55:27.500Z',
        'sortOrder': 6
      }, {
        'id': 13903,
        'name': 'file',
        'imageId': 203927,
        'thumbnail': 'assets/img/mockadvert/bzVSlYeYjXEDuSf0v14s.jpg',
        'small': 'assets/img/mockadvert/xG9ADG9vA0dNW3bj8eGq.jpg',
        'medium': 'assets/img/mockadvert/DfqROoIOx8Zn3hTpT8Z2.jpg',
        'large': 'assets/img/mockadvert/NIKIoxLDa51SN9FdVRyJ.jpg',
        'dateCreated': '2019-03-28T11:55:17.789Z',
        'sortOrder': 7
      }, {
        'id': 13905,
        'name': 'file',
        'imageId': 203929,
        'thumbnail': 'assets/img/mockadvert/2E31JCmmSUXVxyhgukBr.jpg',
        'small': 'assets/img/mockadvert/V8QrHkHyxoyd6GAZyOot.jpg',
        'medium': 'assets/img/mockadvert/EyjLNDcW6sC2cMlAMwIl.jpg',
        'large': 'assets/img/mockadvert/QF9akknytxXzP6Ja74cs.jpg',
        'dateCreated': '2019-03-28T11:55:23.836Z',
        'sortOrder': 8
      }, {
        'id': 13910,
        'name': 'file',
        'imageId': 203934,
        'thumbnail': 'assets/img/mockadvert/SdUiY0ZyMwvDVcZkocav.jpg',
        'small': 'assets/img/mockadvert/9Bqz3dDndywwmkTUPaiY.jpg',
        'medium': 'assets/img/mockadvert/mJiB7iqFy9jB17t3eJdr.jpg',
        'large': 'assets/img/mockadvert/jgZt6wWEfwLMLynydvYj.jpg',
        'dateCreated': '2019-03-28T11:56:13.246Z',
        'sortOrder': 9
      }, {
        'id': 13900,
        'name': '65301_001_3809.jpg',
        'imageId': 203924,
        'thumbnail': 'assets/img/mockadvert/PT5ZDVjwaiOboHgOrzVN.jpg',
        'small': 'assets/img/mockadvert/8jpXHZEKF2otgoYI6xj1.jpg',
        'medium': 'assets/img/mockadvert/YauCoJZQjUzaV3d34xTM.jpg',
        'large': 'assets/img/mockadvert/wiRcovJRDUABAOKgDPzR.jpg',
        'dateCreated': '2019-03-28T11:54:19.887Z',
        'sortOrder': 10
      }],
      'videos': [],
      'description': {
        'en': null,
        'et': 'Väike-Rae elamurajoon, kuhu kerkib peatselt nii kortermaju, ridaelamuid kui eramaju, ' +
          'on hubane ja linnalähedane piirkond.\n\nEsimeses etapis rajatakse kaks kortermaja aadressil ' +
          'Väike-Rae tee 25, neile järgneb peagi kolm kortermaja aadressil Väike-Rae tee 19. ' +
          'Hooned tulevad kolmekordsed ning valikus on kortereid alates 1-toalistest kuni ' +
          'lausa 5-toaliste korteriteni välja. Korterid on läbimõeldud planeeringutega ja varustatud ' +
          'suurte rõdude ning esimestel korrustel avarate terrassidega.\n\nLooduslähedane asukoht, ' +
          'kuid samas paiknemine Tallinna külje all ning logistiliselt hea ligipääsetavus pakub ühtlaselt ' +
          'kiiret ligipääsu nii töökohtadele kui ka kultuurisündmustele. Rae vald on Eesti üks ' +
          'kiiremalt arenevaid omavalitsusi, kus pööratakse erilist tähelepanu elukeskkonna jätkuvale ' +
          'parandamisele.\n\nKorteri juurde võimalik soetada panipaik maksumusega 1500 eurot ja auto ' +
          'parkimiskoht maksumusega 2000 eurot. Lisaparkimiskoha võimalus on samuti olemas.\n\nVaata ' +
          'plaane ja asetusi lisaks projekti kodulehelt www.vaikerae.ee\n\nNB - 3D fotod on illustratiivse ' +
          'iseloomuga ning võivad erineda väljaarendatavast elukeskkonnast.\n\nHelista ja küsi lisa!',
        'fi': null,
        'ru': null,
        'lv': null
      },
      'ownership': null,
      'intendedUsages': 'LIVING',
      'buildYear': '2018',
      'supportingStructure': 'CUSTOM_FIELD_OBJECT_SUPPORTING_STRUCTURE_STONE',
      'roof': null,
      'energyLabel': 'CUSTOM_FIELD_OBJECT_ENERGY_LABEL_C',
      'sanitaryFurnitureState': 'CUSTOM_FIELD_OBJECT_SANITARY_FURNITURE_STATE_NEW',
      'heatingSystem': 'CUSTOM_FIELD_OBJECT_HEATING_SYSTEM_GAS_HEATING',
      'coldWater': 'CUSTOM_FIELD_OBJECT_COLD_WATER_CENTRAL',
      'gasSupply': 'CUSTOM_FIELD_OBJECT_GAS_SUPPLY_CENTRAL',
      'sewerage': 'CUSTOM_FIELD_OBJECT_SEWERAGE_CENTRAL',
      'ventilation': 'CUSTOM_FIELD_OBJECT_VENTILATION_HEAT_RECOVERY',
      'communications': null,
      'alarm': null,
      'parking': null,
      'sanitary': 'CUSTOM_FIELD_OBJECT_SANITARY_SHOWER',
      'security': null,
      'stove': null,
      'additionalInfo': null,
      'purposeOfUse': null,
      'bedroomCount': 1,
      'showStreetNumber': true,
      'showApartmentNumber': true,
      'cadasterImageAsLast': true,
      'similarAdvertsMinPrice': null,
      'similarAdvertsMaxPrice': null,
      'similarAdvertsMinPricePerM2': null,
      'similarAdvertsMaxPricePerM2': null,
      'similarAdvertsAddress': null,
      'similarTransactionsAddress': null,
      'salesSentence': {'en': null, 'et': 'Maja valmib suvi 2019! ', 'fi': null, 'ru': null, 'lv': null},
      'dateCreated': '2019-03-28T11:49:23.321Z',
      'dateModified': '2019-03-28T11:58:01.876Z',
      'visibility': {
        'cadasterNumber': true,
        'stove': true,
        'rooms': true,
        'gasSupply': true,
        'floor': true,
        'additionalInfo': true,
        'coldWater': true,
        'communications': true,
        'closedNetArea': true,
        'ownership': true,
        'propertyArea': true,
        'parking': true,
        'purposeOfUse': true,
        'sanitaryFurnitureState': true,
        'bedroomCount': true,
        'buildYear': true,
        'security': true,
        'supportingStructure': true,
        'intendedUsages': true,
        'roof': true,
        'floors': true,
        'registerNumber': true,
        'alarm': true,
        'heatingSystem': true,
        'energyLabel': true,
        'sanitary': true,
        'ventilation': true,
        'sewerage': true
      },
      'customFieldVisibility': {},
      'comparisons': [],
      'customFields': [],
      'brokers': [177]
    };

    super(mockData);
    this.isMock = true;
    this.addressLite = this.address.address;
  }
}
