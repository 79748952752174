import {Injectable} from '@angular/core';
import {User} from '../../class/user';
import {Observable} from 'rxjs';
import {DataService} from '../data/data.service';
import {Config} from '../../config/config';
import {map, tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {Error} from '../../class/error';
import {HttpClient, HttpParams} from '@angular/common/http';
import {MobileIdResponse} from '../../class/mobile-id';
import {ToastService} from 'ng-uikit-pro-standard';
import {DomainValueService} from '../domain-value/domain-value.service';
import {SmartIdResponse} from '../../class/smart-id';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public static X_AUTH_TOKEN = 'x-auth-token';
  public static X_USER_TYPE = 'x-user-type';
  public static CURRENT_USER = 'current_user';

  public static LOGIN_URL = 'users/login';
  public static LOGOUT_URL = 'users/logout';
  public static REGISTER_URL = 'users/register';
  public static FACEBOOK_LOGIN_URL = 'users/login/facebook';
  public static GOOGLE_LOGIN_URL = 'users/login/google';
  public static GOOGLE_ACTIVATE_URL = 'users/connect/google';
  public static FACEBOOK_ACTIVATE_URL = 'users/connect/facebook';

  public static ID_CARD_LOGIN_URL = 'users/login/id';
  public static MOBILE_ID_LOGIN_URL = 'users/login/mid';
  public static SMART_ID_LOGIN_URL = 'users/login/sid';

  public static GOOGLE = 'GOOGLE';
  public static FACEBOOK = 'FACEBOOK';
  public static X_USER_TYPE_ULTRALIGHT = 'ultralight';
  public static X_USER_TYPE_BRENOLLIS = 'brenollis';

  currentUser: User;

  constructor(private dataService: DataService,
              private config: Config,
              private httpClient: HttpClient,
              private router: Router,
              private domainValueService: DomainValueService,
              private toastService: ToastService) {
  }

  public static getAuthToken() {
    if (localStorage.getItem(AuthService.X_AUTH_TOKEN)) {
      return localStorage.getItem(AuthService.X_AUTH_TOKEN);
    }
  }

  public isUserLoggedIn(): boolean {
    return this.getCurrentUser() != null && !!AuthService.getAuthToken();
  }

  public setCurrentUser(user: User): void {
    this.currentUser = user;
    localStorage.setItem(AuthService.CURRENT_USER, JSON.stringify(this.currentUser));
  }

  public getCurrentUser(): User {
    const userJson = localStorage.getItem(AuthService.CURRENT_USER);
    if (userJson !== null) {
      let user = JSON.parse(userJson);
      user = new User(user);

      return user;
    }
    return null;
  }

  login(email: string, password: string): Observable<boolean> {
    const data = {email: email, password: password};
    return this.dataService.login(AuthService.LOGIN_URL, data).pipe(
      tap(resp => {
        this.setLoginResponse(resp);
      })
    );
  }

  public logout() {
    this.logoutFromServer();
    this.currentUser = null;
    this.dataService.clearLocalStorage();
  }

  redirectToLogin() {
    this.dataService.redirectToLogin();
  }

  logoutFromServer() {
    this.dataService.post(AuthService.LOGOUT_URL).subscribe();
  }

  register(name: String, email: String, companyName: String, canSendNews: boolean, recaptcha) {
    const data = {email: email, companyName: companyName, name: name, canSendNews: canSendNews, recaptcha: recaptcha};
    return this.dataService.login(AuthService.REGISTER_URL, data).pipe(
      tap(resp => {
        this.setRegisterResponse(resp);
      })
    );
  }

  setCurrentUserFromResponse(response) {
    this.setCurrentUser(new User(response));
  }

  facebookLogin(id: string, token: string): Observable<boolean> {
    const data = {id: id, token: token};
    return this.dataService.login(AuthService.FACEBOOK_LOGIN_URL, data).pipe(
      tap(resp => {
        this.parseSocialLoginSuccessResponse(resp);

      }, errorResponse => {
        this.parseSocialLoginErrorResponse(errorResponse);
      })
    );
  }

  googleLogin(id: string, token: string): Observable<boolean> {
    const data = {id: id, token: token};
    return this.dataService.login(AuthService.GOOGLE_LOGIN_URL, data).pipe(
      tap(resp => {
        this.parseSocialLoginSuccessResponse(resp);

      }, errorResponse => {
        this.parseSocialLoginErrorResponse(errorResponse);
      })
    );
  }

  googleActivate(id: string, token: string): Observable<boolean> {
    const data = {id: id, token: token};
    return this.dataService.post(AuthService.GOOGLE_ACTIVATE_URL, data);
  }

  facebookActivate(id: string, token: string): Observable<boolean> {
    const data = {id: id, token: token};
    return this.dataService.post(AuthService.FACEBOOK_ACTIVATE_URL, data);
  }

  public idCardLogin() {
    return this.loginIdCard(AuthService.ID_CARD_LOGIN_URL);
  }

  public loginIdCard(url: string, params?: HttpParams): Observable<any> {
    return this.httpClient.get<any>(this.config.getIdLoginUrl(), {observe: 'response', params}).pipe(map(resp => {
      return this.setMobileSmartIdCardLoginResponse(resp);
    }));
  }

  authMobileId(phoneNumber: string): Observable<MobileIdResponse> {
    let params = new HttpParams();
    params = params.append('phoneNo', phoneNumber);

    return this.httpClient.get(this.config.getApiUrl(AuthService.MOBILE_ID_LOGIN_URL), {
      observe: 'response', params
    }).pipe(
      map(response => {
        const res = new MobileIdResponse(response.body);
        // if this equals TRUE, then XAuthToken has been set
        if (this.setMobileSmartIdCardLoginResponse(response)) {
          res.authToken = AuthService.getAuthToken();
        }
        return res;
      }));
  }

  authSmartId(userIdCode: string): Observable<SmartIdResponse> {
    let params = new HttpParams();
    params = params.append('userIdCode', userIdCode);

    return this.httpClient.get(this.config.getApiUrl(AuthService.SMART_ID_LOGIN_URL), {
      observe: 'response', params
    }).pipe(
      map(response => {
        const res = new SmartIdResponse(response.body);
        // if this equals TRUE, then XAuthToken has been set
        if (this.setMobileSmartIdCardLoginResponse(response)) {
          res.authToken = AuthService.getAuthToken();
        }
        return res;
      }));
  }

  private setAuthToken(authToken: string) {
    localStorage.setItem(AuthService.X_AUTH_TOKEN, authToken);
  }

  private setLoginResponse(resp): boolean {
    if (this.handleRedirect(resp)) {
      return false;
    }
    const xAuthToken = resp.headers.get('x-auth-token');
    const responseBody = resp.body;
    if (responseBody && xAuthToken) {
      const user: User = new User(responseBody);
      this.setCurrentUser(user);
      this.setAuthToken(xAuthToken);
      return true;
    }
    return false;
  }

  private setMobileSmartIdCardLoginResponse(resp): boolean {
    if (this.handleRedirect(resp)) {
      return false;
    }
    const xAuthToken = resp.headers.get(AuthService.X_AUTH_TOKEN);
    const responseBody = resp.body;
    if (responseBody && xAuthToken) {
      this.setAuthToken(xAuthToken);
      return true;
    }
    return false;
  }

  private setRegisterResponse(resp): boolean {
    const xAuthToken = resp.headers.get('x-auth-token');
    const responseBody = resp.body;
    if (responseBody && xAuthToken) {
      const user: User = new User(responseBody.user);
      this.setCurrentUser(user);
      this.setAuthToken(xAuthToken);
      return true;
    }
    return false;
  }

  private parseSocialLoginErrorResponse(response) {
    if (response.errors.id) {
      // id - NOT_FOUND - ‘id’ and ‘accessToken’ are valid but user needs to register
      if (response.errors.id === Error.errorNotFound) {
        this.toastService.error('Kasutaja pole ühendatud sotsiaalkontoga');

        // id - INVALID - unable to validate ‘id’ and ‘accessToken’ with Facebook
      } else if (response.errors.id === Error.errorInvalid) {
        this.toastService.error('Midagi on valesti');
      }
    }
  }

  private parseSocialLoginSuccessResponse(response) {
    this.toastService.success('Sisselogimine õnnestus');
    this.setLoginResponse(response);
    if (this.getCurrentUser()) {
      this.domainValueService.setDomainValuesToLocalStorage().subscribe(_ => this.router.navigate(['']));
    }
  }

  handleRedirect(resp) {
    if (resp.headers) {
      const xUserType = resp.headers.get(AuthService.X_USER_TYPE);
      if (xUserType === AuthService.X_USER_TYPE_BRENOLLIS) {
        localStorage.clear();
        window.location.href = this.config.getConfig().brenollisFrontEndUrl;
        return true;
      }
    }

    return false;
  }

}
