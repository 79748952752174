import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Config} from '../../config/config';
import {Router} from '@angular/router';
import {ToastService} from 'ng-uikit-pro-standard';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private httpClient: HttpClient,
              private config: Config,
              private router: Router,
              private toastService: ToastService) {
  }

  public static replaceId(id, url, fieldName = 'id') {
    return url.replace('{' + fieldName + '}', id);
  }

  public getOne(url: string, params?: HttpParams): Observable<any> {
    return this.errorIntercept(this.httpClient.get<any>(this.config.getApiUrl(url), {params}));
  }

  public getList(url: string, params?: HttpParams): Observable<any[]> {
    return this.errorIntercept(this.httpClient.get<any[]>(this.config.getApiUrl(url), {params}));
  }

  public getJSONPList(url: string): Observable<any[]> {
    return this.errorIntercept(this.httpClient.jsonp<any[]>(url, 'callback'));
  }

  public post(url: string, data?: string | Object): Observable<any> {
    return this.errorIntercept(this.httpClient.post<any>(this.config.getApiUrl(url), data));
  }

  public put(url: string, data: string | Object): Observable<any> {
    return this.errorIntercept(this.httpClient.put<any>(this.config.getApiUrl(url), data));
  }

  public patch(url: string, data: string | Object): Observable<any> {
    return this.errorIntercept(this.httpClient.patch<any>(this.config.getApiUrl(url), data));
  }

  public delete(url: string): Observable<any> {
    return this.errorIntercept(this.httpClient.delete<any>(this.config.getApiUrl(url)));
  }

  public login(url: string, data: string | Object): Observable<any> {
    return this.loginErrorIntercept(this.httpClient.post(this.config.getApiUrl(url), data, {observe: 'response'}));
  }

  public redirectToLogin() {
    this.router.navigate(['/login']);
  }

  public redirectToHome() {
    this.router.navigate(['']);
  }

  public redirect401(redirectUrl?: string): void {
    this.clearLocalStorage();
    this.toastService.error('Tekkis viga - logige  uuesti sisse');
    this.redirectToLogin();
  }

  public redirect403(): void {
    this.toastService.error('Tekkis viga - puudub juurdepääsuõigus');
    this.redirectToHome();
  }

  public getErrorCodeForField(error, field: String) {
    error.rows.forEach((i, e) => {
      if (e.field === field) {
        return e.code;
      }
    });
    return false;
  }

  private errorIntercept(observable: Observable<any>): Observable<any> {
    return observable.pipe(
      catchError((err, source) => {
        if (err.status === 401) {
          this.redirect401();
        } else if (err.status === 403) {
          this.redirect403();
        } else if (err.status === 502 || err.status === 0) {
          this.toastService.error('Teenus pole hetkel kättesaadav. Palun proovige mõne aja pärast uuesti');
        } else {
          this.parseErrors(err);
        }
        return throwError(err);
      }));
  }

  private loginErrorIntercept(observable: Observable<any>): Observable<any> {
    return observable.pipe(
      catchError((err, source) => {

        this.parseErrors(err);
        return throwError(err);
      }));
  }

  clearLocalStorage() {
    localStorage.clear();
  }

  parseErrors(err) {
    err.errors = {};
    if (err.error && err.error.rows) {
      err.error.rows.forEach((row, i) => {
        err.errors[row.field] = row.code;
      });
    }
  }
}
