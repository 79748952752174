import {BrowserModule} from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';

import 'hammerjs';
import 'mousetrap';
import {AppComponent} from './app.component';
import {HeaderComponent} from './header/header.component';
import {AdvertDetailComponent} from './advert/detail/detail.component';
import {AdvertEditComponent} from './advert/edit/edit.component';
import {AdvertListComponent} from './advert/list/list.component';
import {HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClientModule} from '@angular/common/http';
import {DataService} from './service/data/data.service';
import {AddHeaderInterceptor} from './interceptor/add-header-interceptor';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
  ButtonsModule,
  CardsFreeModule,
  DatepickerModule,
  MDBBootstrapModulesPro,
  SelectModule,
  ToastModule,
  WavesModule
} from 'ng-uikit-pro-standard';
import {AppRoutingModule} from './app-routing.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {
  MAT_DATE_LOCALE,
  MatChipsModule,
  MatDatepickerModule,
  MatFormFieldModule,
  MatInputModule
} from '@angular/material';
import {Config} from './config/config';
import {LoginComponent} from './login/login/login.component';
import {SimpleLayoutComponent} from './layout/simple-layout/simple-layout.component';
import {FullLayoutComponent} from './layout/full-layout/full-layout.component';
import {UserProfileComponent} from './user/user-profile/user-profile.component';
import {CompanyProfileComponent} from './user/company-profile/company-profile.component';
import {NotificationsComponent} from './user/notifications/notifications.component';
import {LoggedInGuard} from './guard/logged-in.guard';
import {NotLoggedInGuard} from './guard/not-logged-in.guard';
import {TagInputModule} from 'ngx-chips';
import {RECAPTCHA_SETTINGS, RecaptchaModule, RecaptchaSettings} from 'ng-recaptcha';
import {RecaptchaFormsModule} from 'ng-recaptcha/forms';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {Nl2BrPipeModule} from 'nl2br-pipe';
import {provideConfig} from './factory/factories';
import {AuthServiceConfig, SocialLoginModule} from 'angularx-social-login';
import {CardComponent} from './advert/list/card/card.component';
import {ArchiveMenuComponent} from './advert/archive-menu/archive-menu.component';
import {SortablejsModule} from 'angular-sortablejs';
import {GalleryModule} from '@ngx-gallery/core';
import {LightboxModule} from '@ngx-gallery/lightbox';
import {FileUploadModule} from 'ng2-file-upload';
import {GooglePlaceModule} from 'ngx-google-places-autocomplete';
import {LaddaModule} from 'angular2-ladda';
import {ForgotPasswordComponent} from './login/forgot-password/forgot-password.component';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule} from '@angular/material-moment-adapter';
import {NgSelectModule} from '@ng-select/ng-select';
import {PublishMenuComponent} from './advert/publish-menu/publish-menu.component';
import {UnactivatedAccountBarComponent} from './unactivated-account-bar/unactivated-account-bar.component';
import {MockAdvertModalComponent} from './component/mock-advert-modal/mock-advert-modal.component';
import {ArchivedAdvertBarComponent} from './archived-advert-bar/archived-advert-bar.component';
import { ConfirmEmailChangeComponent } from './user/confirm-email-change/confirm-email-change.component';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    AppComponent,
    AdvertDetailComponent,
    AdvertEditComponent,
    AdvertListComponent,
    HeaderComponent,
    LoginComponent,
    SimpleLayoutComponent,
    FullLayoutComponent,
    UserProfileComponent,
    CompanyProfileComponent,
    NotificationsComponent,
    CardComponent,
    ArchiveMenuComponent,
    PublishMenuComponent,
    ForgotPasswordComponent,
    UnactivatedAccountBarComponent,
    MockAdvertModalComponent,
    ArchivedAdvertBarComponent,
    ConfirmEmailChangeComponent
  ],
  imports: [
    TagInputModule,
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    BrowserModule,
    FormsModule,
    MDBBootstrapModulesPro.forRoot(),
    AppRoutingModule,
    SelectModule,
    WavesModule,
    ButtonsModule,
    CardsFreeModule,
    DatepickerModule,
    MatMomentDateModule,
    BrowserModule,
    MatDatepickerModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    ToastModule.forRoot(),
    InfiniteScrollModule,
    Nl2BrPipeModule,
    SocialLoginModule,
    SortablejsModule.forRoot({animation: 150}),
    GalleryModule.withConfig({
      loadingMode: 'indeterminate'
    }),
    GooglePlaceModule,
    LightboxModule,
    FileUploadModule,
    LaddaModule.forRoot({
      style: 'expand-right',
      spinnerSize: 40,
      spinnerColor: '#7DAF1F',
      spinnerLines: 12
    }),
    NgSelectModule,
    MatChipsModule
  ],
  providers: [
    LoggedInGuard,
    NotLoggedInGuard,
    DataService,
    {
      provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
      useValue: {useUtc: true}
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'et'
    },
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddHeaderInterceptor,
      multi: true,
    },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {siteKey: Config.getGoogleReCaptchaKey()} as RecaptchaSettings,
    },
    Config
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
