import {AbstractControl, ValidatorFn} from '@angular/forms';
import {isNull} from 'util';

export class InputValidators {

  static lengthValidator = (minLength: number, maxLength: number): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value !== undefined && !isNull(control.value) && (control.value.length < minLength || control.value.length > maxLength)) {
        return {'length': true};
      }
      return null;
    };
  }

  static cadasterNumberValidator = (): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const cadasterRg = /^[0-9]{5}:[0-9]{3}:[0-9]{4}$/;
      if (control.value !== undefined && !isNull(control.value) && (!cadasterRg.test(control.value))) {
        return {'cadasterNumber': true};
      }
      return null;
    };
  }
}
