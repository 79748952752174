export class ObjectValues {
  public static OBJECT_TYPE = {
    sale: {
      et: 'Müük',
    },
    rental: {
      et: 'Üür',
    }
  };
  public static OBJECT_INTENDED_USAGES = {
    AGRICULTURE: {
      et: 'Maatulundusmaa',
    },
    MINING_INDUSTRY: {
      et: 'Mäetööstusmaa',
    },
    OTHER: {
      et: 'Sihtotstarbeta maa',
    },
    DEFENCE: {
      et: 'Riigikaitsemaa',
    },
    PEAT_INDUSTRY: {
      et: 'Turbatööstusmaa',
    },
    TRANSPORT: {
      et: 'Transpordimaa',
    },
    LIVING: {
      et: 'Elamumaa',
    },
    SOCIAL_BUILDING: {
      et: 'Ühiskondlike ehitiste maa',
    },
    MANUFACTURING: {
      et: 'Tootmismaa',
    },
    LANDFILL: {
      et: 'Jäätmehoidla maa',
    },
    BUSINESS: {
      et: 'Ärimaa',
    },
    WATER: {
      et: 'Veekogude maa',
    },
    SOCIAL: {
      et: 'Sotsiaalmaa',
    },
    PROTECTION: {
      et: 'Kaitsealune maa',
    }
  };
}
