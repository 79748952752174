import {Component, OnInit} from '@angular/core';
import {AuthService} from '../service/auth/auth.service';

@Component({
  selector: 'app-unactivated-account-bar',
  templateUrl: './unactivated-account-bar.component.html',
  styleUrls: ['./unactivated-account-bar.component.scss']
})
export class UnactivatedAccountBarComponent implements OnInit {

  display = false;
  email = '';
  constructor(private authService: AuthService) { }

  ngOnInit() {
    this.email = this.authService.getCurrentUser().email;
    this.display = this.authService.getCurrentUser().isNotActivated();
  }

}
