import {HttpParams} from '@angular/common/http';

export class AdvertFilter {
  query = '';
  channelIds: string[];
  active = 'active';
  page = 0;

  getHttpParams() {
    let params = new HttpParams();
    if (this.canFilterByQuery()) {
      params = params.append('query', this.query);
    }

    if (this.page > 0) {
      params = params.append('page', this.page.toString());
    }

    if (this.channelIds) {
      params = params.append('consumers', this.channelIds.join(','));
    }

    if (this.active) {
      params = params.append('status', this.active);
    }
    return params;
  }

  canFilterByQuery(): boolean {
    return this.query.length >= 3;
  }

  newPage() {
    this.page++;
  }

  resetPageNumber() {
    this.page = 0;
  }


}
