export class ConfigData {
  public static CONFIG_DATA = {
    'backendUri': 'https://ultralight.brenollis.ee/api',
    'googleReCaptchaKey': '6LefXpwUAAAAAHgw9pvnPYFmzqZP1pzDJUFN3jxT',
    'facebookAppId': '325802984708779',
    'googleClientId': '118838113638-6qc1tuudlcbau36511rjhdhcu4d4bmda.apps.googleusercontent.com',
    'googleClientSecret': 'RPvSEBsjyVyAICZpyBklm0Bn',
    'idCard': {
      'authUrl': 'https://id.brenollis.ee',
    },
    'mobileId': {
      'firstPollInterval': 5000,
      'pollInterval': 2000,
    },
    'smartId': {
      'firstPollInterval': 2500,
      'pollInterval': 1000,
    },
    'inAdsUrl': 'https://inaadress.maaamet.ee/inaadress/gazetteer?adrid=',
    'brenollisFrontEndUrl': 'https://pindi.brenollis.ee',
    'ultralightFrontEndUrl': 'https://ultralight.brenollis.ee',
  };
}
