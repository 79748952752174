import {AdvertLite} from './advert-lite';
import {Address} from './address';
import {Image} from './image';

export class Advert extends AdvertLite {
  address: Address;
  bedroomCount: number;
  ownership: string;
  cadasterNumber: string;
  buildYear: string;
  supportingStructure: string;
  sanitaryFurnitureState: string;
  heatingSystem: string;
  coldWater: string;
  ventilation: string;
  security: string;
  stove: string;
  parking: string;
  registerNumber: string;
  energyLabel: string;
  roof: string;
  sanitary: string;
  sewerage: string;
  gasSupply: string;
  communications: string;
  showApartmentNumber = false;
  showStreetNumber = false;
  alarm: string;
  booked = false;
  cadasterImageAsLast = false;
  additionalInfo = [];
  similarAdvertsMinPrice: number;
  similarAdvertsMaxPrice: number;
  similarAdvertsMinPricePerM2: number;
  similarAdvertsMaxPricePerM2: number;
  similarAdvertsAddress: string;
  similarTransactionsAddress: string;
  salesSentence: {
    'en': 'string',
    'et': 'string',
    'fi': 'string',
    'lv': 'string',
    'ru': 'string'
  };
  description: {
    'en': 'string',
    'et': 'string',
    'fi': 'string',
    'lv': 'string',
    'ru': 'string'
  };
  visibility: {};
  images: Image[] = [];

  constructor(json?: any) {
    super(json);
    if (!json) {
      return;
    }

    this.address = new Address(json.address);
    this.ownership = json.ownership;
    this.cadasterNumber = json.cadasterNumber;
    this.buildYear = json.buildYear;
    this.supportingStructure = json.supportingStructure;
    this.sanitaryFurnitureState = json.sanitaryFurnitureState;
    this.heatingSystem = json.heatingSystem;
    this.coldWater = json.coldWater;
    this.ventilation = json.ventilation;
    this.security = json.security;
    this.stove = json.stove;
    this.parking = json.parking;
    this.registerNumber = json.registerNumber;
    this.energyLabel = json.energyLabel;
    this.roof = json.roof;
    this.sanitary = json.sanitary;
    this.sewerage = json.sewerage;
    this.gasSupply = json.gasSupply;
    this.communications = json.communications;
    this.showApartmentNumber = json.showApartmentNumber;
    this.showStreetNumber = json.showStreetNumber;
    this.alarm = json.alarm;
    this.booked = json.booked;
    this.cadasterImageAsLast = json.cadasterImageAsLast;
    if (typeof json.additionalInfo === 'string') {
      this.additionalInfo = json.additionalInfo.split(',');
    }
    this.salesSentence = json.salesSentence;
    this.description = json.description;
    this.bedroomCount = json.bedroomCount;
    this.visibility = json.visibility;
    this.similarAdvertsMinPrice = json.similarAdvertsMinPrice;
    this.similarAdvertsMaxPrice = json.similarAdvertsMaxPrice;
    this.similarAdvertsMinPricePerM2 = json.similarAdvertsMinPricePerM2;
    this.similarAdvertsMaxPricePerM2 = json.similarAdvertsMaxPricePerM2;
    this.similarAdvertsAddress = json.similarAdvertsAddress;
    this.similarTransactionsAddress = json.similarTransactionsAddress;
    if (json.images) {
      json.images.forEach(imageData => {
        this.images.push(new Image(imageData));
      });
    }
  }

  getStreetAddress() {
    return this.address.address.split(',', 2).join(', ');
  }
}
