export class Address {
  address: string = null;
  externalId: string = null;
  lat: number = null;
  lng: number = null;
  locality: string = null;
  subLocality: string = null;
  country = 'est';
  room: string = null;
  house: string = null;
  street: string = null;
  city: string = null;
  county: string = null;
  ehak: string = null;
  ehakmk: string = null;
  ehakov: string = null;

  constructor(json?: any) {
    if (!json) {
      return;
    }
    this.address = json.address;
    this.externalId = json.externalId;
    this.lat = json.lat;
    this.lng = json.lng;
    this.locality = json.locality;
    this.subLocality = json.sublocality;
    this.room = json.room;
    this.house = json.house;
    this.street = json.street;
    this.city = json.city;
    this.county = json.county;
    this.ehak = json.ehak;
    this.ehakmk = json.ehakmk;
    this.ehakov = json.ehakov;
  }
}
